import React from 'react';
import PropTypes from 'prop-types';

// api
import { resetHotelApiKey } from 'api';

import { Modal } from '@material-ui/core';

// components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';

const ModalResetApiKey = ({ open, onClose, onReset, hotelId }) => {
  const resetAction = () => {
    return resetHotelApiKey(hotelId)
      .then((res) => {
        onReset(res.data);
        onClose();
      });
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <>
        <Card
          style={ {
            width: '25%',
            position: 'absolute',
            float: 'left',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          } }
        >
          <CardBody>
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              Are you sure you want to reset the Api Key for this hotel?
            </p>
          </CardBody>
          <CardFooter>
            <Button
              color='info'
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <SpinnerButton
              onClick={resetAction}
              btnText='Reset Api Key'
              btnColor='danger'
              successMsg='Api Key reset'
              failureMsg='Unable to reset Api Key'
            />
          </CardFooter>
        </Card>
      </>
    </Modal>
  );
};

export default ModalResetApiKey;

ModalResetApiKey.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  hotelId: PropTypes.number
};
