import {
  drawerWidth,
  transition,
  container
} from 'assets/jss/material-dashboard-react.js';

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100%'
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  content: {
    ...container,
    padding: '15px',
    height: 'calc(100vh - 126px)'
  }
});

export default appStyle;
