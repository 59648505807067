import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export default function DropdownButton({ menu, buttonClasses, ButtonIcon, buttonClassname }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false)
      anchorRef.current.focus();

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>

      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={buttonClasses}
      >
        <ButtonIcon />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {
                    menu.map((m, idx) =>
                      <MenuItem
                        key={`${idx}-${m.id}`}
                        className={buttonClassname(m.isSelected)}
                        onClick={() => {
                          handleClose();
                          m.onClick();
                        }}
                      >
                        {m.text}
                      </MenuItem>
                    )
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </div>
  );
};

DropdownButton.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    onClick: PropTypes.func,
    text: PropTypes.string,
    isSelected: PropTypes.bool
  })),
  buttonClasses: PropTypes.string,
  ButtonIcon: PropTypes.object,
  buttonClassname: PropTypes.func
};

DropdownButton.defaultProps = {
  menu: [],
  buttonClassname: () => ''
};
