import { parseISO } from 'date-fns';

export const dateComparatorReverse = (a, b) => (-1 * dateComparator(a, b));

export const dateComparator = (a, b) => (parseISO(a).getTime() - parseISO(b).getTime());

export const numberComparator = (a, b) => (a - b);

export const stringComparator = (a, b, numericStr = false) =>
  (a ? a.localeCompare(b, undefined, { numeric: numericStr }) : (b ? 1 : 0));
