import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';
import linksStyle from './linksStyle';

// verifies if routeName is the one active (in browser input)
// FIXME: Use withrouter in order to access to 'match' object
const activeRoute = routeName => window.location.href.indexOf(routeName) > -1;

const useStyles = makeStyles(linksStyle);

const Links = ({ routes }) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const whiteFontClasses = classNames({
          'sidebar-active-text': activeRoute(prop.layout + prop.path),
          'sidebar-text': !activeRoute(prop.layout + prop.path)
        })

        return (
          <NavLink
            to={prop.layout + prop.path}
            className={classNames(classes.item, 'item-element')}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classNames('item-element')} alignItems='center'>
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
}

Links.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    layout: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    name: PropTypes.string
  }))
};

export default Links;
