import React from 'react';
import PropTypes from 'prop-types';

import { fetchBookingEngines } from 'api';

import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { dangerColor } from 'assets/jss/material-dashboard-react';

// components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomAutocomplete from 'components/CustomAutocomplete';
import MailList from './MailList';

import { TimezonesOptions } from 'assets/jss/timezones';
import { CountryCodes } from 'assets/jss/countrycodes';
import LiveChatAvailability from 'components/LiveChatAvailability';

const styles = {
  asterisk: {
    color: dangerColor[0]
  }
};

const useStyles = makeStyles(styles);

const FormHotel = ({
  handleChange,
  handleBeChanges,
  form,
  formValidation,
  disableInstallationName
}) => {
  const classes = useStyles();

  const showInstallationNameError = formValidation && formValidation.installationName && formValidation.installationName.error;

  const [bookingEngineOptions, setBookingEngineOptions] = React.useState([
    {
      bookingEngineType: '',
      bookingEngineName: '',
      configKeys: []
    }
  ]);

  React.useEffect(() => {
    fetchBookingEngines()
      .then(res => { setBookingEngineOptions(res.data); })
      .catch(err => { console.error(err); });
  }, []);

  const selectedBookingEngineConfigKeys = (beType) => {
    const beOption = bookingEngineOptions.find(be => be.bookingEngineType === beType);
    return beOption ? beOption.configKeys || [] : [];
  };

  const updateBookingEngineConfig = (key, value) => {
    const currentObj = JSON.parse(form.bookingEngineConfig);
    currentObj[key] = value;
    handleChange({ target: { id: 'bookingEngineConfig', value: JSON.stringify(currentObj) } });
  }

  const changeBookingEngineType = (event) => {
    const baseConfig = selectedBookingEngineConfigKeys(event.target.value).reduce((prev, val) => {
      return {
        ...prev,
        [val]: ''
      }
    }, {});

    handleBeChanges({
      bookingEngineType: event.target.value,
      bookingEngineConfig: JSON.stringify(baseConfig)
    });
  }

  const handleEmailChange = (totalEmailList, formKey) => {
    handleChange({
      target: {
        id: formKey,
        value: totalEmailList
      }
    })
  }

  return (
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText='Name'
            required
            id='name'
            formControlProps={{
              fullWidth: true,
              onChange: handleChange
            }}
            inputProps={{
              value: form.name
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText='Hotel Identifier'
            required
            id='installationName'
            error={showInstallationNameError}
            errorMessage={showInstallationNameError ? formValidation.installationName.text : null}
            formControlProps={{
              fullWidth: true,
              onChange: handleChange
            }}
            inputProps={{
              value: form.installationName,
              disabled: disableInstallationName
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <MailList
            id="mailTo"
            label="Mail to"
            mails={form.mailTo}
            required
            handleChange={(newEmailTot) => {
              handleEmailChange(newEmailTot, 'mailTo')
            }}>
          </MailList>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <MailList
            id="mailCC"
            label="Mail cc"
            mails={form.mailCc}
            handleChange={(newEmailTot) => {
              handleEmailChange(newEmailTot, 'mailCc')
            }}>
          </MailList>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <MailList
            id="mailCCN"
            label="Mail ccn"
            mails={form.mailCcn}
            handleChange={(newEmailTot) => {
              handleEmailChange(newEmailTot, 'mailCcn')
            }}>
          </MailList>
        </GridItem>

        <GridItem xs={6} sm={6} md={2}>
          <div>
            <CustomInput
              labelText='Whatsapp number ID'
              id='whatsappNumber'
              formControlProps={{
                fullWidth: true,
                onChange: handleChange
              }}
              inputProps={{
                value: form.whatsappNumber
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.whatsappNlpActive}
                  onChange={(event) => { handleBeChanges({ whatsappNlpActive: event.target.checked }); }}
                  color='primary'
                  disabled={!form.whatsappNumber}
                />}
              label={'Bot enabled ?'}
              labelPlacement='end'
              value='end'
            />
          </div>
        </GridItem>
        <GridItem xs={6} sm={6} md={2}>
          <CustomInput
            labelText='Facebook access token'
            id='facebookAccessToken'
            formControlProps={{
              fullWidth: true,
              onChange: handleChange
            }}
            inputProps={{
              value: form.facebookAccessToken
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText='Phone'
            id='phoneNumber'
            formControlProps={{
              fullWidth: true,
              onChange: handleChange
            }}
            inputProps={{
              value: form.phoneNumber
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText='Chatbot Id'
            id='chatbotId'
            formControlProps={{
              fullWidth: true,
              onChange: handleChange
            }}
            inputProps={{
              value: form.chatbotId
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomAutocomplete
            value={form.timezone}
            labelText={'Timezone'}
            required={true}
            options={TimezonesOptions.map(t => t.value).concat([''])}
            getOptionLabel={option => { const v = TimezonesOptions.find(t => t.value === option); return v ? v.text : ''; }}
            onChange={value => handleBeChanges({ timezone: value })}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText='Booking Engine type'
            id='bookingEngineType'
            required
            onChange={changeBookingEngineType}
            value={form.bookingEngineType}
            menuItems={
              bookingEngineOptions.map(be => ({ value: be.bookingEngineType, text: be.bookingEngineName }))
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText='Country Code'
            id='isoCountryCode'
            required
            onChange={value => handleChange(value)}
            value={form.isoCountryCode}
            menuItems={
              CountryCodes.map(cc => ({ value: cc.value, text: cc.text }))
            }
          />
        </GridItem>
      </GridContainer>
        { selectedBookingEngineConfigKeys(form.bookingEngineType).length > 0
          ? <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p>
                <FormLabel
                  required
                  style={{ color: '#A9AEBC' }}
                  classes={{
                    asterisk: classes.asterisk
                  }}
                >
                  Booking Engine configuration
                </FormLabel>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{
                width: '100%',
                minHeight: '100px',
                border: '1px solid #A9AEBC',
                borderRadius: '5px'
              }}>
                {selectedBookingEngineConfigKeys(form.bookingEngineType).map(key => (
                  <GridItem key={key} xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={key}
                      id={key}
                      key={key}
                      required
                      formControlProps={{
                        fullWidth: true,
                        onChange: (a) => {
                          updateBookingEngineConfig(a.target.id, a.target.value);
                        }
                      }}
                      inputProps={{
                        value: JSON.parse(form.bookingEngineConfig)[key]
                      }}
                    />
                  </GridItem>
                ))}
              </div>
            </GridItem>
          </GridContainer>
          : null }
      <LiveChatAvailability liveChatAvailabilityType={form.liveChatAvailabilityType} liveChatTimeSlotList={form.liveChatTimeSlotList} handleBeChanges={handleBeChanges}/>
    </CardBody>
  );
};

export default FormHotel;

FormHotel.propTypes = {
  handleChange: PropTypes.func,
  handleBeChanges: PropTypes.func,
  form: PropTypes.shape({
    chatbotId: PropTypes.string,
    installationName: PropTypes.string,
    mailCc: PropTypes.string,
    mailCcn: PropTypes.string,
    mailTo: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    bookingEngineUrl: PropTypes.string,
    bookingEngineType: PropTypes.string,
    bookingEngineConfig: PropTypes.string,
    whatsappNumber: PropTypes.string,
    whatsappNlpActive: PropTypes.bool,
    timezone: PropTypes.string,
    isoCountryCode: PropTypes.string,
    liveChatAvailabilityType: PropTypes.string,
    liveChatTimeSlotList: PropTypes.array,
    facebookAccessToken: PropTypes.string
  }),
  formValidation: PropTypes.shape({
    installationName: PropTypes.shape({
      error: PropTypes.bool,
      text: PropTypes.string
    }),
    mailTo: PropTypes.shape({
      error: PropTypes.bool,
      text: PropTypes.string
    }),
    mailCc: PropTypes.shape({
      error: PropTypes.bool,
      text: PropTypes.string
    }),
    mailCcn: PropTypes.shape({
      error: PropTypes.bool,
      text: PropTypes.string
    })
  }),
  disableInstallationName: PropTypes.bool
};

FormHotel.defaultProps = {
  disableInstallationName: false
};
