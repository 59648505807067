import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

// @material-ui/icons
import Menu from '@material-ui/icons/Menu';

// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import HotelNavigation from 'containers/HotelNavigation';

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';

const useStyles = makeStyles(styles);

export default function Header({ color, handleDrawerToggle }) {
  const classes = useStyles();

  const appBarClasses = classNames(classes.appBar, {
    [classes[color]]: color
  });

  return (
    <AppBar className={classNames(appBarClasses)}>
      <Toolbar className={classes.container}>
        <HotelNavigation />
        <AdminNavbarLinks />
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  handleDrawerToggle: PropTypes.func
};
