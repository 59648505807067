import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { isMailValidOrEmpty } from 'utils/validators';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const MailList = ({
  handleChange,
  mails,
  id,
  label,
  errorMessage,
  errorDuplicated,
  required
}) => {
  const [currentEmail, setCurrentEmail] = React.useState('');
  const [showEmailError, setShowEmailError] = React.useState(false);

  const mailArray = (mails === '') ? [] : (mails + '').split(',');
  let mailTot = null;

  const handleTextEmail = (event) => {
    setCurrentEmail(event.target.value);
  }

  const isAlreadyPresent = () => {
    return (mailArray.includes(currentEmail));
  }

  React.useEffect(() => {
    setShowEmailError(!isMailValidOrEmpty(currentEmail) || isAlreadyPresent());
  }, [currentEmail])

  const shouldAddBeDisabled = () => {
    if (showEmailError || currentEmail === '' || isAlreadyPresent()) return true;
  }

  const showErrorMessage = () => {
    if (isAlreadyPresent()) return errorDuplicated;
    else return errorMessage;
  }

  return (
    <div style={{
      whiteSpace: 'nowrap'
    }}>
      <div style={{
        width: '100%',
        display: 'flex',
        margin: '27px 0 0 0'
      }}>
      <CustomInput
        labelText={label}
        required={required}
        id={id}
        error={showEmailError}
        errorMessage={showErrorMessage()}
        formControlProps={{
          style: { flex: '1 1 auto', margin: '0' }
        }}
        inputProps={{
          value: currentEmail,
          onChange: handleTextEmail
        }}
      />

      <Button
        key={'add'}
        style={{
          color: '#999',
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          flex: 'flex: 0 0 40px',
          minWidth: 'unset',
          alignItems: 'center'
        }}
        disabled= {shouldAddBeDisabled(mailArray)}
        onClick={() => {
          mailArray.push(currentEmail);
          mailTot = mailArray.join(',');
          setCurrentEmail('');
          handleChange(mailTot);
        }}
      >
        <AddCircleOutlineOutlinedIcon />
      </Button>
      </div>

      <div>
        <ul style={{ padding: '0px 0px 0px 5px' }}>
          {mailArray.map(mail => (
            <li key={mail}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
            >
              <span style={{
                alignSelf: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              >{mail}</span>
              <Button
                key={'remove'}
                style={{
                  color: '#999',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  minWidth: '40px'
                }}
                onClick={() => {
                  const index = mailArray.indexOf(mail);
                  mailArray.splice(index, 1);
                  mailTot = mailArray.join(',');
                  handleChange(mailTot);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </li>
          ))}
        </ul>
      </div>

    </div>
  )
}

export default MailList;

MailList.propTypes = {
  handleChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  errorDuplicated: PropTypes.string,
  mails: PropTypes.string,
  required: PropTypes.bool
}

MailList.defaultProps = {
  mails: '',
  required: false,
  errorMessage: 'Email not valid',
  errorDuplicated: 'Already present'
};
