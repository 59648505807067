import { createSelector } from 'reselect';

const liveChatList = state => state.messages.liveChat || [];

const unreadMessagesAggregator = (liveChats) => {
  const newMessage = liveChats.find(l => l.unreadMessage);
  return !!newMessage;
};

export const unreadMessages = createSelector(
  liveChatList,
  unreadMessagesAggregator
);
