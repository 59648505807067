import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from 'assets/jss/material-dashboard-react.js';

const headerStyle = {
  appBar: {
    backgroundColor: whiteColor,
    boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.05)',
    borderBottom: '0',
    marginBottom: '0',
    position: 'relative',
    width: '100%',
    // paddingTop: '10px',
    zIndex: '1029',
    color: grayColor[7],
    border: '0',
    borderRadius: '3px',
    padding: '0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    height: '96px',
    display: 'block'
  },
  container: {
    ...container,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  fullHeight: {
    height: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  selected: {
    '&,&:focus,&:hover': {
      background: 'linear-gradient(180deg, #FFFFFF 95.83%, #B8E5E3 95.83%, #B8E5E3 100%)',
      color: '#858ca6 !important'
    }
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '30px',
    fontSize: '20px',
    fontWeight: 500,
    borderRadius: '3px',
    textTransform: 'none',
    color: '#ced0da',
    margin: '0'
  },
  appResponsive: {
    top: '8px'
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  }
};

export default headerStyle;
