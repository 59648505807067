import { ALL_STATISTICS } from '../actions';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  summary: {
    value: 0,
    change: 0
  },
  labels: [],
  values: {
    activationRate: [],
    retentionRate: [],
    fallbackRate: [],
    selfServiceRate: [],
    interactionPerUser: []
  }
};

export function conversationReducer(state = initialState, action) {
  if (!action || !action.payload || !action.payload[0])
    return state;

  if (action.type === ALL_STATISTICS) {
    const conversationDate = action.payload[0].data;
    return {
      summary: conversationDate.summary,
      labels: conversationDate.labels,
      values: {
        activationRate: conversationDate.activationRate,
        retentionRate: conversationDate.retentionRate,
        fallbackRate: conversationDate.fallbackRate,
        selfServiceRate: conversationDate.selfServiceRate,
        interactionPerUser: conversationDate.avgInteractionPerUser
      }
    }
  }

  return state;
}

export default conversationReducer;
