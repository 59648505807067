import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

export const useStyles = makeStyles({
  statisticsCard: {
    paddingTop: '25px',
    paddingBottom: '18px',
    paddingLeft: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  statsContainer: {
    marginTop: '14px'
  },
  statsIcon: {
    width: '27px',
    height: '27px',
    color: 'rgba(0, 0, 0, 0.3)'
  },
  headerContent: {
    paddingLeft: '20px'
  },
  statsValue: {
    paddingLeft: '20px',
    fontSize: '48px',
    color: '#4ba6a2'
  },
  statsComparison: {
    marginTop: '18px'
  },
  percentage: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#427af5'
  },
  comparisonPeriod: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  checkboxLabel: {
    fontSize: '8px',
    color: 'rgba(0, 0, 0, 0.75)'
  },
  checkboxContainer: {
    marginBottom: '30px'
  },
  cardEqualHeight: {
    height: '100%'
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '75%'
  },
  searchSecondColumn: {
    paddingLeft: '50px !important'
  },
  ...styles
});
