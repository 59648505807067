import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, ListItemText, MenuItem, Select, makeStyles } from '@material-ui/core';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle';

const useStyle = makeStyles(styles);

const MultipleSelect = ({ dataList, selectedElements, setSelectedElements }) => {
  const classes = useStyle();

  const selectValue = (!selectedElements || selectedElements.length === 0) ? [-1] : selectedElements;

  const handleSelectElement = (event) => {
    const filterSelectedItems = (event.target.value || []).filter(v => v !== -1);
    setSelectedElements(filterSelectedItems);
  };

  const renderSelectValues = selected => {
    if (selected.length === 1 && selected[0] === -1)
      return 'All';

    return dataList.filter(el => selected.indexOf(el.id) > -1).map(el => el.name).join(', ');
  };

  return (
    <Select
      classes={{ root: classes.dropdownHotel }}
      style={{
        width: '100%',
        boxSizing: 'border-box',
        padding: '10px 10px 10px 10px',
        height: 'initial',
        margin: '0',
        background: '#F1F2F7',
        borderRadius: '6px'
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        PaperProps: {
          style: {
            maxHeight: 50 * 6
          }
        },
        getContentAnchorEl: null,
        disableAutoFocusItem: true
      }}
      multiple
      disableUnderline
      value={selectValue}
      onChange={handleSelectElement}
      renderValue={renderSelectValues}
    >
      <MenuItem key={-1} value={-1} style={{ display: 'none' }} />
      {dataList.map(el => (
        <MenuItem key={el.id} value={el.id} style={{ height: '50px' }}>
          <Checkbox style={{ color: '#5FBFBB' }} checked={selectValue.indexOf(el.id) > -1} />
          <ListItemText primary={el.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

MultipleSelect.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  selectedElements: PropTypes.arrayOf(PropTypes.number),
  setSelectedElements: PropTypes.func
};

MultipleSelect.defaultProps = {
  dataList: [],
  selectedElements: [],
  setSelectedElements: () => {}
};

export default MultipleSelect;
