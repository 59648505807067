export const TimezonesOptions = [
  { value: 'Pacific/Midway', text: 'Pacific/Midway' },
  { value: 'Pacific/Niue', text: 'Pacific/Niue' },
  { value: 'Pacific/Pago_Pago', text: 'Pacific/Pago_Pago' },
  { value: 'Pacific/Honolulu', text: 'Pacific/Honolulu' },
  { value: 'Pacific/Johnston', text: 'Pacific/Johnston' },
  { value: 'Pacific/Rarotonga', text: 'Pacific/Rarotonga' },
  { value: 'Pacific/Tahiti', text: 'Pacific/Tahiti' },
  { value: 'Pacific/Marquesas', text: 'Pacific/Marquesas' },
  { value: 'America/Adak', text: 'America/Adak' },
  { value: 'Pacific/Gambier', text: 'Pacific/Gambier' },
  { value: 'America/Anchorage', text: 'America/Anchorage' },
  { value: 'America/Juneau', text: 'America/Juneau' },
  { value: 'America/Metlakatla', text: 'America/Metlakatla' },
  { value: 'America/Nome', text: 'America/Nome' },
  { value: 'America/Sitka', text: 'America/Sitka' },
  { value: 'America/Yakutat', text: 'America/Yakutat' },
  { value: 'Pacific/Pitcairn', text: 'Pacific/Pitcairn' },
  { value: 'America/Creston', text: 'America/Creston' },
  { value: 'America/Dawson', text: 'America/Dawson' },
  { value: 'America/Dawson_Creek', text: 'America/Dawson_Creek' },
  { value: 'America/Fort_Nelson', text: 'America/Fort_Nelson' },
  { value: 'America/Hermosillo', text: 'America/Hermosillo' },
  { value: 'America/Los_Angeles', text: 'America/Los_Angeles' },
  { value: 'America/Phoenix', text: 'America/Phoenix' },
  { value: 'America/Santa_Isabel', text: 'America/Santa_Isabel' },
  { value: 'America/Tijuana', text: 'America/Tijuana' },
  { value: 'America/Vancouver', text: 'America/Vancouver' },
  { value: 'America/Whitehorse', text: 'America/Whitehorse' },
  { value: 'America/Belize', text: 'America/Belize' },
  { value: 'America/Boise', text: 'America/Boise' },
  { value: 'America/Cambridge_Bay', text: 'America/Cambridge_Bay' },
  { value: 'America/Chihuahua', text: 'America/Chihuahua' },
  { value: 'America/Costa_Rica', text: 'America/Costa_Rica' },
  { value: 'America/Denver', text: 'America/Denver' },
  { value: 'America/Edmonton', text: 'America/Edmonton' },
  { value: 'America/El_Salvador', text: 'America/El_Salvador' },
  { value: 'America/Guatemala', text: 'America/Guatemala' },
  { value: 'America/Inuvik', text: 'America/Inuvik' },
  { value: 'America/Managua', text: 'America/Managua' },
  { value: 'America/Mazatlan', text: 'America/Mazatlan' },
  { value: 'America/Ojinaga', text: 'America/Ojinaga' },
  { value: 'America/Regina', text: 'America/Regina' },
  { value: 'America/Swift_Current', text: 'America/Swift_Current' },
  { value: 'America/Tegucigalpa', text: 'America/Tegucigalpa' },
  { value: 'America/Yellowknife', text: 'America/Yellowknife' },
  { value: 'Pacific/Galapagos', text: 'Pacific/Galapagos' },
  { value: 'America/Atikokan', text: 'America/Atikokan' },
  { value: 'America/Bahia_Banderas', text: 'America/Bahia_Banderas' },
  { value: 'America/Bogota', text: 'America/Bogota' },
  { value: 'America/Cancun', text: 'America/Cancun' },
  { value: 'America/Chicago', text: 'America/Chicago' },
  { value: 'America/Eirunepe', text: 'America/Eirunepe' },
  { value: 'America/Guayaquil', text: 'America/Guayaquil' },
  { value: 'America/Indiana/Knox', text: 'America/Indiana/Knox' },
  { value: 'America/Indiana/Tell_City', text: 'America/Indiana/Tell_City' },
  { value: 'America/Jamaica', text: 'America/Jamaica' },
  { value: 'America/Lima', text: 'America/Lima' },
  { value: 'America/Matamoros', text: 'America/Matamoros' },
  { value: 'America/Menominee', text: 'America/Menominee' },
  { value: 'America/Merida', text: 'America/Merida' },
  { value: 'America/Mexico_City', text: 'America/Mexico_City' },
  { value: 'America/Monterrey', text: 'America/Monterrey' },
  { value: 'America/North_Dakota/Beulah', text: 'America/North_Dakota/Beulah' },
  { value: 'America/North_Dakota/Center', text: 'America/North_Dakota/Center' },
  { value: 'America/North_Dakota/New_Salem', text: 'America/North_Dakota/New_Salem' },
  { value: 'America/Panama', text: 'America/Panama' },
  { value: 'America/Rainy_River', text: 'America/Rainy_River' },
  { value: 'America/Rankin_Inlet', text: 'America/Rankin_Inlet' },
  { value: 'America/Resolute', text: 'America/Resolute' },
  { value: 'America/Rio_Branco', text: 'America/Rio_Branco' },
  { value: 'America/Winnipeg', text: 'America/Winnipeg' },
  { value: 'Pacific/Easter', text: 'Pacific/Easter' },
  { value: 'America/Caracas', text: 'America/Caracas' },
  { value: 'America/Anguilla', text: 'America/Anguilla' },
  { value: 'America/Antigua', text: 'America/Antigua' },
  { value: 'America/Aruba', text: 'America/Aruba' },
  { value: 'America/Asuncion', text: 'America/Asuncion' },
  { value: 'America/Barbados', text: 'America/Barbados' },
  { value: 'America/Blanc-Sablon', text: 'America/Blanc-Sablon' },
  { value: 'America/Boa_Vista', text: 'America/Boa_Vista' },
  { value: 'America/Campo_Grande', text: 'America/Campo_Grande' },
  { value: 'America/Cayman', text: 'America/Cayman' },
  { value: 'America/Cuiaba', text: 'America/Cuiaba' },
  { value: 'America/Curacao', text: 'America/Curacao' },
  { value: 'America/Detroit', text: 'America/Detroit' },
  { value: 'America/Dominica', text: 'America/Dominica' },
  { value: 'America/Grand_Turk', text: 'America/Grand_Turk' },
  { value: 'America/Grenada', text: 'America/Grenada' },
  { value: 'America/Guadeloupe', text: 'America/Guadeloupe' },
  { value: 'America/Guyana', text: 'America/Guyana' },
  { value: 'America/Havana', text: 'America/Havana' },
  { value: 'America/Indiana/Indianapolis', text: 'America/Indiana/Indianapolis' },
  { value: 'America/Indiana/Marengo', text: 'America/Indiana/Marengo' },
  { value: 'America/Indiana/Petersburg', text: 'America/Indiana/Petersburg' },
  { value: 'America/Indiana/Vevay', text: 'America/Indiana/Vevay' },
  { value: 'America/Indiana/Vincennes', text: 'America/Indiana/Vincennes' },
  { value: 'America/Indiana/Winamac', text: 'America/Indiana/Winamac' },
  { value: 'America/Iqaluit', text: 'America/Iqaluit' },
  { value: 'America/Kentucky/Louisville', text: 'America/Kentucky/Louisville' },
  { value: 'America/Kentucky/Monticello', text: 'America/Kentucky/Monticello' },
  { value: 'America/Kralendijk', text: 'America/Kralendijk' },
  { value: 'America/La_Paz', text: 'America/La_Paz' },
  { value: 'America/Lower_Princes', text: 'America/Lower_Princes' },
  { value: 'America/Manaus', text: 'America/Manaus' },
  { value: 'America/Marigot', text: 'America/Marigot' },
  { value: 'America/Martinique', text: 'America/Martinique' },
  { value: 'America/Montserrat', text: 'America/Montserrat' },
  { value: 'America/Nassau', text: 'America/Nassau' },
  { value: 'America/New_York', text: 'America/New_York' },
  { value: 'America/Nipigon', text: 'America/Nipigon' },
  { value: 'America/Pangnirtung', text: 'America/Pangnirtung' },
  { value: 'America/Port_of_Spain', text: 'America/Port_of_Spain' },
  { value: 'America/Port-au-Prince', text: 'America/Port-au-Prince' },
  { value: 'America/Porto_Velho', text: 'America/Porto_Velho' },
  { value: 'America/Puerto_Rico', text: 'America/Puerto_Rico' },
  { value: 'America/Santo_Domingo', text: 'America/Santo_Domingo' },
  { value: 'America/St_Barthelemy', text: 'America/St_Barthelemy' },
  { value: 'America/St_Kitts', text: 'America/St_Kitts' },
  { value: 'America/St_Lucia', text: 'America/St_Lucia' },
  { value: 'America/St_Thomas', text: 'America/St_Thomas' },
  { value: 'America/St_Vincent', text: 'America/St_Vincent' },
  { value: 'America/Thunder_Bay', text: 'America/Thunder_Bay' },
  { value: 'America/Toronto', text: 'America/Toronto' },
  { value: 'America/Tortola', text: 'America/Tortola' },
  { value: 'America/Araguaina', text: 'America/Araguaina' },
  { value: 'America/Argentina/Buenos_Aires', text: 'America/Argentina/Buenos_Aires' },
  { value: 'America/Argentina/Catamarca', text: 'America/Argentina/Catamarca' },
  { value: 'America/Argentina/Cordoba', text: 'America/Argentina/Cordoba' },
  { value: 'America/Argentina/Jujuy', text: 'America/Argentina/Jujuy' },
  { value: 'America/Argentina/La_Rioja', text: 'America/Argentina/La_Rioja' },
  { value: 'America/Argentina/Mendoza', text: 'America/Argentina/Mendoza' },
  { value: 'America/Argentina/Rio_Gallegos', text: 'America/Argentina/Rio_Gallegos' },
  { value: 'America/Argentina/Salta', text: 'America/Argentina/Salta' },
  { value: 'America/Argentina/San_Juan', text: 'America/Argentina/San_Juan' },
  { value: 'America/Argentina/San_Luis', text: 'America/Argentina/San_Luis' },
  { value: 'America/Argentina/Tucuman', text: 'America/Argentina/Tucuman' },
  { value: 'America/Argentina/Ushuaia', text: 'America/Argentina/Ushuaia' },
  { value: 'America/Bahia', text: 'America/Bahia' },
  { value: 'America/Belem', text: 'America/Belem' },
  { value: 'America/Cayenne', text: 'America/Cayenne' },
  { value: 'America/Fortaleza', text: 'America/Fortaleza' },
  { value: 'America/Glace_Bay', text: 'America/Glace_Bay' },
  { value: 'America/Goose_Bay', text: 'America/Goose_Bay' },
  { value: 'America/Halifax', text: 'America/Halifax' },
  { value: 'America/Maceio', text: 'America/Maceio' },
  { value: 'America/Moncton', text: 'America/Moncton' },
  { value: 'America/Montevideo', text: 'America/Montevideo' },
  { value: 'America/Paramaribo', text: 'America/Paramaribo' },
  { value: 'America/Recife', text: 'America/Recife' },
  { value: 'America/Santarem', text: 'America/Santarem' },
  { value: 'America/Santiago', text: 'America/Santiago' },
  { value: 'America/Sao_Paulo', text: 'America/Sao_Paulo' },
  { value: 'America/Thule', text: 'America/Thule' },
  { value: 'Antarctica/Palmer', text: 'Antarctica/Palmer' },
  { value: 'Antarctica/Rothera', text: 'Antarctica/Rothera' },
  { value: 'Atlantic/Bermuda', text: 'Atlantic/Bermuda' },
  { value: 'Atlantic/Stanley', text: 'Atlantic/Stanley' },
  { value: 'America/St_Johns', text: 'America/St_Johns' },
  { value: 'America/Godthab', text: 'America/Godthab' },
  { value: 'America/Miquelon', text: 'America/Miquelon' },
  { value: 'America/Noronha', text: 'America/Noronha' },
  { value: 'Atlantic/South_Georgia', text: 'Atlantic/South_Georgia' },
  { value: 'Atlantic/Cape_Verde', text: 'Atlantic/Cape_Verde' },
  { value: 'Africa/Abidjan', text: 'Africa/Abidjan' },
  { value: 'Africa/Accra', text: 'Africa/Accra' },
  { value: 'Africa/Bamako', text: 'Africa/Bamako' },
  { value: 'Africa/Banjul', text: 'Africa/Banjul' },
  { value: 'Africa/Bissau', text: 'Africa/Bissau' },
  { value: 'Africa/Casablanca', text: 'Africa/Casablanca' },
  { value: 'Africa/Conakry', text: 'Africa/Conakry' },
  { value: 'Africa/Dakar', text: 'Africa/Dakar' },
  { value: 'Africa/El_Aaiun', text: 'Africa/El_Aaiun' },
  { value: 'Africa/Freetown', text: 'Africa/Freetown' },
  { value: 'Africa/Lome', text: 'Africa/Lome' },
  { value: 'Africa/Monrovia', text: 'Africa/Monrovia' },
  { value: 'Africa/Nouakchott', text: 'Africa/Nouakchott' },
  { value: 'Africa/Ouagadougou', text: 'Africa/Ouagadougou' },
  { value: 'Africa/Sao_Tome', text: 'Africa/Sao_Tome' },
  { value: 'America/Danmarkshavn', text: 'America/Danmarkshavn' },
  { value: 'America/Scoresbysund', text: 'America/Scoresbysund' },
  { value: 'Atlantic/Azores', text: 'Atlantic/Azores' },
  { value: 'Atlantic/Reykjavik', text: 'Atlantic/Reykjavik' },
  { value: 'Atlantic/St_Helena', text: 'Atlantic/St_Helena' },
  { value: 'Africa/Algiers', text: 'Africa/Algiers' },
  { value: 'Africa/Bangui', text: 'Africa/Bangui' },
  { value: 'Africa/Brazzaville', text: 'Africa/Brazzaville' },
  { value: 'Africa/Douala', text: 'Africa/Douala' },
  { value: 'Africa/Kinshasa', text: 'Africa/Kinshasa' },
  { value: 'Africa/Lagos', text: 'Africa/Lagos' },
  { value: 'Africa/Libreville', text: 'Africa/Libreville' },
  { value: 'Africa/Luanda', text: 'Africa/Luanda' },
  { value: 'Africa/Malabo', text: 'Africa/Malabo' },
  { value: 'Africa/Ndjamena', text: 'Africa/Ndjamena' },
  { value: 'Africa/Niamey', text: 'Africa/Niamey' },
  { value: 'Africa/Porto-Novo', text: 'Africa/Porto-Novo' },
  { value: 'Africa/Tunis', text: 'Africa/Tunis' },
  { value: 'Africa/Windhoek', text: 'Africa/Windhoek' },
  { value: 'Atlantic/Canary', text: 'Atlantic/Canary' },
  { value: 'Atlantic/Faroe', text: 'Atlantic/Faroe' },
  { value: 'Atlantic/Madeira', text: 'Atlantic/Madeira' },
  { value: 'Europe/Dublin', text: 'Europe/Dublin' },
  { value: 'Europe/Guernsey', text: 'Europe/Guernsey' },
  { value: 'Europe/Isle_of_Man', text: 'Europe/Isle_of_Man' },
  { value: 'Europe/Jersey', text: 'Europe/Jersey' },
  { value: 'Europe/Lisbon', text: 'Europe/Lisbon' },
  { value: 'Europe/London', text: 'Europe/London' },
  { value: 'Africa/Blantyre', text: 'Africa/Blantyre' },
  { value: 'Africa/Bujumbura', text: 'Africa/Bujumbura' },
  { value: 'Africa/Cairo', text: 'Africa/Cairo' },
  { value: 'Africa/Ceuta', text: 'Africa/Ceuta' },
  { value: 'Africa/Gaborone', text: 'Africa/Gaborone' },
  { value: 'Africa/Harare', text: 'Africa/Harare' },
  { value: 'Africa/Johannesburg', text: 'Africa/Johannesburg' },
  { value: 'Africa/Kigali', text: 'Africa/Kigali' },
  { value: 'Africa/Lubumbashi', text: 'Africa/Lubumbashi' },
  { value: 'Africa/Lusaka', text: 'Africa/Lusaka' },
  { value: 'Africa/Maputo', text: 'Africa/Maputo' },
  { value: 'Africa/Maseru', text: 'Africa/Maseru' },
  { value: 'Africa/Mbabane', text: 'Africa/Mbabane' },
  { value: 'Africa/Tripoli', text: 'Africa/Tripoli' },
  { value: 'Antarctica/Troll', text: 'Antarctica/Troll' },
  { value: 'Arctic/Longyearbyen', text: 'Arctic/Longyearbyen' },
  { value: 'Europe/Amsterdam', text: 'Europe/Amsterdam' },
  { value: 'Europe/Andorra', text: 'Europe/Andorra' },
  { value: 'Europe/Belgrade', text: 'Europe/Belgrade' },
  { value: 'Europe/Berlin', text: 'Europe/Berlin' },
  { value: 'Europe/Bratislava', text: 'Europe/Bratislava' },
  { value: 'Europe/Brussels', text: 'Europe/Brussels' },
  { value: 'Europe/Budapest', text: 'Europe/Budapest' },
  { value: 'Europe/Busingen', text: 'Europe/Busingen' },
  { value: 'Europe/Copenhagen', text: 'Europe/Copenhagen' },
  { value: 'Europe/Gibraltar', text: 'Europe/Gibraltar' },
  { value: 'Europe/Kaliningrad', text: 'Europe/Kaliningrad' },
  { value: 'Europe/Ljubljana', text: 'Europe/Ljubljana' },
  { value: 'Europe/Luxembourg', text: 'Europe/Luxembourg' },
  { value: 'Europe/Madrid', text: 'Europe/Madrid' },
  { value: 'Europe/Malta', text: 'Europe/Malta' },
  { value: 'Europe/Monaco', text: 'Europe/Monaco' },
  { value: 'Europe/Oslo', text: 'Europe/Oslo' },
  { value: 'Europe/Paris', text: 'Europe/Paris' },
  { value: 'Europe/Podgorica', text: 'Europe/Podgorica' },
  { value: 'Europe/Prague', text: 'Europe/Prague' },
  { value: 'Europe/Rome', text: 'Europe/Rome' },
  { value: 'Europe/San_Marino', text: 'Europe/San_Marino' },
  { value: 'Europe/Sarajevo', text: 'Europe/Sarajevo' },
  { value: 'Europe/Skopje', text: 'Europe/Skopje' },
  { value: 'Europe/Stockholm', text: 'Europe/Stockholm' },
  { value: 'Europe/Tirane', text: 'Europe/Tirane' },
  { value: 'Europe/Vaduz', text: 'Europe/Vaduz' },
  { value: 'Europe/Vatican', text: 'Europe/Vatican' },
  { value: 'Europe/Vienna', text: 'Europe/Vienna' },
  { value: 'Europe/Warsaw', text: 'Europe/Warsaw' },
  { value: 'Europe/Zagreb', text: 'Europe/Zagreb' },
  { value: 'Europe/Zurich', text: 'Europe/Zurich' },
  { value: 'Africa/Addis_Ababa', text: 'Africa/Addis_Ababa' },
  { value: 'Africa/Asmara', text: 'Africa/Asmara' },
  { value: 'Africa/Dar_es_Salaam', text: 'Africa/Dar_es_Salaam' },
  { value: 'Africa/Djibouti', text: 'Africa/Djibouti' },
  { value: 'Africa/Juba', text: 'Africa/Juba' },
  { value: 'Africa/Kampala', text: 'Africa/Kampala' },
  { value: 'Africa/Khartoum', text: 'Africa/Khartoum' },
  { value: 'Africa/Mogadishu', text: 'Africa/Mogadishu' },
  { value: 'Africa/Nairobi', text: 'Africa/Nairobi' },
  { value: 'Antarctica/Syowa', text: 'Antarctica/Syowa' },
  { value: 'Asia/Aden', text: 'Asia/Aden' },
  { value: 'Asia/Amman', text: 'Asia/Amman' },
  { value: 'Asia/Baghdad', text: 'Asia/Baghdad' },
  { value: 'Asia/Bahrain', text: 'Asia/Bahrain' },
  { value: 'Asia/Beirut', text: 'Asia/Beirut' },
  { value: 'Asia/Damascus', text: 'Asia/Damascus' },
  { value: 'Asia/Gaza', text: 'Asia/Gaza' },
  { value: 'Asia/Hebron', text: 'Asia/Hebron' },
  { value: 'Asia/Jerusalem', text: 'Asia/Jerusalem' },
  { value: 'Asia/Kuwait', text: 'Asia/Kuwait' },
  { value: 'Asia/Nicosia', text: 'Asia/Nicosia' },
  { value: 'Asia/Qatar', text: 'Asia/Qatar' },
  { value: 'Asia/Riyadh', text: 'Asia/Riyadh' },
  { value: 'Europe/Athens', text: 'Europe/Athens' },
  { value: 'Europe/Bucharest', text: 'Europe/Bucharest' },
  { value: 'Europe/Chisinau', text: 'Europe/Chisinau' },
  { value: 'Europe/Helsinki', text: 'Europe/Helsinki' },
  { value: 'Europe/Istanbul', text: 'Europe/Istanbul' },
  { value: 'Europe/Kiev', text: 'Europe/Kiev' },
  { value: 'Europe/Mariehamn', text: 'Europe/Mariehamn' },
  { value: 'Europe/Minsk', text: 'Europe/Minsk' },
  { value: 'Europe/Moscow', text: 'Europe/Moscow' },
  { value: 'Europe/Riga', text: 'Europe/Riga' },
  { value: 'Europe/Simferopol', text: 'Europe/Simferopol' },
  { value: 'Europe/Sofia', text: 'Europe/Sofia' },
  { value: 'Europe/Tallinn', text: 'Europe/Tallinn' },
  { value: 'Europe/Uzhgorod', text: 'Europe/Uzhgorod' },
  { value: 'Europe/Vilnius', text: 'Europe/Vilnius' },
  { value: 'Europe/Volgograd', text: 'Europe/Volgograd' },
  { value: 'Europe/Zaporozhye', text: 'Europe/Zaporozhye' },
  { value: 'Indian/Antananarivo', text: 'Indian/Antananarivo' },
  { value: 'Indian/Comoro', text: 'Indian/Comoro' },
  { value: 'Indian/Mayotte', text: 'Indian/Mayotte' },
  { value: 'Asia/Dubai', text: 'Asia/Dubai' },
  { value: 'Asia/Muscat', text: 'Asia/Muscat' },
  { value: 'Asia/Tbilisi', text: 'Asia/Tbilisi' },
  { value: 'Asia/Yerevan', text: 'Asia/Yerevan' },
  { value: 'Europe/Samara', text: 'Europe/Samara' },
  { value: 'Indian/Mahe', text: 'Indian/Mahe' },
  { value: 'Indian/Mauritius', text: 'Indian/Mauritius' },
  { value: 'Indian/Reunion', text: 'Indian/Reunion' },
  { value: 'Asia/Kabul', text: 'Asia/Kabul' },
  { value: 'Asia/Tehran', text: 'Asia/Tehran' },
  { value: 'Antarctica/Mawson', text: 'Antarctica/Mawson' },
  { value: 'Asia/Aqtau', text: 'Asia/Aqtau' },
  { value: 'Asia/Aqtobe', text: 'Asia/Aqtobe' },
  { value: 'Asia/Ashgabat', text: 'Asia/Ashgabat' },
  { value: 'Asia/Baku', text: 'Asia/Baku' },
  { value: 'Asia/Dushanbe', text: 'Asia/Dushanbe' },
  { value: 'Asia/Karachi', text: 'Asia/Karachi' },
  { value: 'Asia/Oral', text: 'Asia/Oral' },
  { value: 'Asia/Samarkand', text: 'Asia/Samarkand' },
  { value: 'Asia/Tashkent', text: 'Asia/Tashkent' },
  { value: 'Asia/Yekaterinburg', text: 'Asia/Yekaterinburg' },
  { value: 'Indian/Kerguelen', text: 'Indian/Kerguelen' },
  { value: 'Indian/Maldives', text: 'Indian/Maldives' },
  { value: 'Asia/Colombo', text: 'Asia/Colombo' },
  { value: 'Asia/Kolkata', text: 'Asia/Kolkata' },
  { value: 'Asia/Kathmandu', text: 'Asia/Kathmandu' },
  { value: 'Antarctica/Vostok', text: 'Antarctica/Vostok' },
  { value: 'Asia/Almaty', text: 'Asia/Almaty' },
  { value: 'Asia/Bishkek', text: 'Asia/Bishkek' },
  { value: 'Asia/Dhaka', text: 'Asia/Dhaka' },
  { value: 'Asia/Novosibirsk', text: 'Asia/Novosibirsk' },
  { value: 'Asia/Omsk', text: 'Asia/Omsk' },
  { value: 'Asia/Qyzylorda', text: 'Asia/Qyzylorda' },
  { value: 'Asia/Thimphu', text: 'Asia/Thimphu' },
  { value: 'Asia/Urumqi', text: 'Asia/Urumqi' },
  { value: 'Indian/Chagos', text: 'Indian/Chagos' },
  { value: 'Asia/Rangoon', text: 'Asia/Rangoon' },
  { value: 'Indian/Cocos', text: 'Indian/Cocos' },
  { value: 'Antarctica/Davis', text: 'Antarctica/Davis' },
  { value: 'Asia/Bangkok', text: 'Asia/Bangkok' },
  { value: 'Asia/Ho_Chi_Minh', text: 'Asia/Ho_Chi_Minh' },
  { value: 'Asia/Jakarta', text: 'Asia/Jakarta' },
  { value: 'Asia/Krasnoyarsk', text: 'Asia/Krasnoyarsk' },
  { value: 'Asia/Novokuznetsk', text: 'Asia/Novokuznetsk' },
  { value: 'Asia/Phnom_Penh', text: 'Asia/Phnom_Penh' },
  { value: 'Asia/Pontianak', text: 'Asia/Pontianak' },
  { value: 'Asia/Vientiane', text: 'Asia/Vientiane' },
  { value: 'Indian/Christmas', text: 'Indian/Christmas' },
  { value: 'Antarctica/Casey', text: 'Antarctica/Casey' },
  { value: 'Asia/Brunei', text: 'Asia/Brunei' },
  { value: 'Asia/Chita', text: 'Asia/Chita' },
  { value: 'Asia/Hong_Kong', text: 'Asia/Hong_Kong' },
  { value: 'Asia/Hovd', text: 'Asia/Hovd' },
  { value: 'Asia/Irkutsk', text: 'Asia/Irkutsk' },
  { value: 'Asia/Kuala_Lumpur', text: 'Asia/Kuala_Lumpur' },
  { value: 'Asia/Kuching', text: 'Asia/Kuching' },
  { value: 'Asia/Macau', text: 'Asia/Macau' },
  { value: 'Asia/Makassar', text: 'Asia/Makassar' },
  { value: 'Asia/Manila', text: 'Asia/Manila' },
  { value: 'Asia/Shanghai', text: 'Asia/Shanghai' },
  { value: 'Asia/Singapore', text: 'Asia/Singapore' },
  { value: 'Asia/Taipei', text: 'Asia/Taipei' },
  { value: 'Australia/Perth', text: 'Australia/Perth' },
  { value: 'Asia/Pyongyang', text: 'Asia/Pyongyang' },
  { value: 'Australia/Eucla', text: 'Australia/Eucla' },
  { value: 'Asia/Choibalsan', text: 'Asia/Choibalsan' },
  { value: 'Asia/Dili', text: 'Asia/Dili' },
  { value: 'Asia/Jayapura', text: 'Asia/Jayapura' },
  { value: 'Asia/Khandyga', text: 'Asia/Khandyga' },
  { value: 'Asia/Seoul', text: 'Asia/Seoul' },
  { value: 'Asia/Tokyo', text: 'Asia/Tokyo' },
  { value: 'Asia/Ulaanbaatar', text: 'Asia/Ulaanbaatar' },
  { value: 'Asia/Yakutsk', text: 'Asia/Yakutsk' },
  { value: 'Pacific/Palau', text: 'Pacific/Palau' },
  { value: 'Australia/Adelaide', text: 'Australia/Adelaide' },
  { value: 'Australia/Broken_Hill', text: 'Australia/Broken_Hill' },
  { value: 'Australia/Darwin', text: 'Australia/Darwin' },
  { value: 'Antarctica/DumontDUrville', text: 'Antarctica/DumontDUrville' },
  { value: 'Asia/Magadan', text: 'Asia/Magadan' },
  { value: 'Asia/Sakhalin', text: 'Asia/Sakhalin' },
  { value: 'Asia/Ust-Nera', text: 'Asia/Ust-Nera' },
  { value: 'Asia/Vladivostok', text: 'Asia/Vladivostok' },
  { value: 'Australia/Brisbane', text: 'Australia/Brisbane' },
  { value: 'Australia/Currie', text: 'Australia/Currie' },
  { value: 'Australia/Hobart', text: 'Australia/Hobart' },
  { value: 'Australia/Lindeman', text: 'Australia/Lindeman' },
  { value: 'Australia/Melbourne', text: 'Australia/Melbourne' },
  { value: 'Australia/Sydney', text: 'Australia/Sydney' },
  { value: 'Pacific/Chuuk', text: 'Pacific/Chuuk' },
  { value: 'Pacific/Guam', text: 'Pacific/Guam' },
  { value: 'Pacific/Port_Moresby', text: 'Pacific/Port_Moresby' },
  { value: 'Pacific/Saipan', text: 'Pacific/Saipan' },
  { value: 'Australia/Lord_Howe', text: 'Australia/Lord_Howe' },
  { value: 'Antarctica/Macquarie', text: 'Antarctica/Macquarie' },
  { value: 'Asia/Srednekolymsk', text: 'Asia/Srednekolymsk' },
  { value: 'Pacific/Bougainville', text: 'Pacific/Bougainville' },
  { value: 'Pacific/Efate', text: 'Pacific/Efate' },
  { value: 'Pacific/Guadalcanal', text: 'Pacific/Guadalcanal' },
  { value: 'Pacific/Kosrae', text: 'Pacific/Kosrae' },
  { value: 'Pacific/Norfolk', text: 'Pacific/Norfolk' },
  { value: 'Pacific/Noumea', text: 'Pacific/Noumea' },
  { value: 'Pacific/Pohnpei', text: 'Pacific/Pohnpei' },
  { value: 'Antarctica/McMurdo', text: 'Antarctica/McMurdo' },
  { value: 'Asia/Anadyr', text: 'Asia/Anadyr' },
  { value: 'Asia/Kamchatka', text: 'Asia/Kamchatka' },
  { value: 'Pacific/Auckland', text: 'Pacific/Auckland' },
  { value: 'Pacific/Fiji', text: 'Pacific/Fiji' },
  { value: 'Pacific/Funafuti', text: 'Pacific/Funafuti' },
  { value: 'Pacific/Kwajalein', text: 'Pacific/Kwajalein' },
  { value: 'Pacific/Majuro', text: 'Pacific/Majuro' },
  { value: 'Pacific/Nauru', text: 'Pacific/Nauru' },
  { value: 'Pacific/Tarawa', text: 'Pacific/Tarawa' },
  { value: 'Pacific/Wake', text: 'Pacific/Wake' },
  { value: 'Pacific/Wallis', text: 'Pacific/Wallis' },
  { value: 'Pacific/Chatham', text: 'Pacific/Chatham' },
  { value: 'Pacific/Apia', text: 'Pacific/Apia' },
  { value: 'Pacific/Enderbury', text: 'Pacific/Enderbury' },
  { value: 'Pacific/Fakaofo', text: 'Pacific/Fakaofo' },
  { value: 'Pacific/Tongatapu', text: 'Pacific/Tongatapu' },
  { value: 'Pacific/Kiritimati', text: 'Pacific/Kiritimati' }
];
