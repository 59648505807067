import { connect } from 'react-redux';

import { loadReservationsList } from 'store/reducer/reservationReducer';

import ReservationComponent from './ReservationComponent';

const mapStateToProps = (state) => ({
  tableData: state.reservationData.reservations,
  currentHotelId: state.hotel
})

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  onRefresh: (hotelId) => loadReservationsList(dispatch, hotelId)
});

// container component/smart component (interacts with redux)
const ReservationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationComponent);

export default ReservationContainer;
