import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, withStyles, InputAdornment } from '@material-ui/core';

const styles = {
  root: {
    padding: 0,
    paddingTop: 10,
    margin: 0,
    background: '#F1F2F7',
    height: '40px',
    paddingLeft: '20px',
    borderRadius: '6px',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
  input: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)'
  }
};

const TTHSearchField = ({ classes, ...props }) => (
  <TextField
    className={classes.root}
    label=""
    hiddenLabel
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      disableUnderline: true,
      className: classes.input
    }}
    {...props}
  />
);

TTHSearchField.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string
  })
}

export default withStyles(styles)(TTHSearchField);
