import { connect } from 'react-redux';

import ConfigurationHotel from './component';

// read from redux store
const mapStateToProps = (state) => ({
  hotelId: state.hotel
});

// container component/smart component (interacts with redux)
const UserConfigurationContainer = connect(
  mapStateToProps
)(ConfigurationHotel);

export default UserConfigurationContainer;
