import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import messagesStyle from './style';

const useLocalStyles = makeStyles(messagesStyle);

const SessionExpiredMessageBox = () => {
  const classes = useLocalStyles();

  return <Grid container style={{ marginTop: '10xp', marginBottom: '10px', flexWrap: 'nowrap' }}>
    <div className={classes.chatMessageBox}>
      <div style={{ textAlign: 'center' }}>
        <span>This session is expired</span>
        <br/>
        <span>Whatsapp session expires after 24 hours</span>
      </div>
    </div>
  </Grid>;
};

export default SessionExpiredMessageBox;
