import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Sidebar from 'components/Sidebar';
import PerfectScrollView from 'components/PerfectScrollView';

import { backofficeRoutes as routes } from 'routes';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => (
      <Route
        path={'/backoffice' + prop.path}
        component={prop.component}
        key={key}
        exact
      />
    ))}
    <Redirect from="/backoffice" to="/backoffice/users" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function BackOffice ({ loadUser, ...rest }) {
  // styles
  const classes = useStyles();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    loadUser();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960)
      setMobileOpen(false);
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  },
  []
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes.filter(r => r.sidebar)}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
      <PerfectScrollView className={classes.mainPanel}>
        <div className={classes.content}>
          {switchRoutes}
        </div>
      </PerfectScrollView>
    </div>
  );
}

BackOffice.propTypes = {
  loadUser: PropTypes.func
};
