import React from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';

import { useStyles } from '../styles';
import { Grid, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { SeriesACheckbox, SeriesBCheckbox, SeriesCCheckbox, SeriesDCheckbox } from './Checkboxes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { cloneDeep, max, zipWith, sum } from 'lodash';

const getCheckbox = (index, isChecked) => {
  switch (index % 4) {
    case 0:
      return <SeriesACheckbox checked={isChecked}/>;
    case 1:
      return <SeriesBCheckbox checked={isChecked}/>;
    case 2:
      return <SeriesCCheckbox checked={isChecked}/>;
    case 3:
      return <SeriesDCheckbox checked={isChecked}/>;
  }
};

const checkedAllOff = data => data.reduce((acc, obj, index) => (
  {
    ...acc,
    [obj.key]: index === 0
  }), {});

const refreshChartDataFactory = (data, labels) => (currentStatus) => generateData(data, labels, currentStatus);

const generateData = (data, labels, currentStatus) => {
  return {
    labels: labels.map(l => l.toUpperCase()),
    series: data.map(s => {
      if (currentStatus[s.key])
        return s.data;

      return [];
    })
  };
}

export default function StatsChart({ labels, data, config, type = 'Line' }) {
  const classes = useStyles();
  const chartOptions = cloneDeep(config);
  const [chartState, setChartState] = React.useState({
    data: {
      labels,
      series: []
    },
    config: chartOptions
  });

  const [checked, setChecked] = React.useState(checkedAllOff(data));
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const refreshChartData = refreshChartDataFactory(data, labels);

  const updateChart = c => {
    const currentData = refreshChartData(c);

    // add tooltip to each value of the series
    currentData.series = currentData.series.map(series => series ? series.map((val, index) => ({ meta: currentData.labels[index], value: val })) : []);

    const arrayData = currentData.series.flat()
    const currentConfig = { // Avoid mutation of chartState by copying
      options: { ...chartState.config.options },
      animation: { ...chartState.config.animation }
    };

    if (type === 'Bar' && currentConfig.options.stackBars)
      currentConfig.options.high = max(zipWith(...currentData.series, (...elements) => sum(elements.map(data => data && data.value)))) || undefined;
    else
      currentConfig.options.high = max(arrayData.map(data => data.value));
    currentConfig.options.axisX.showLabel = arrayData.length > 0;
    currentConfig.options.axisY.showLabel = arrayData.length > 0;

    setChartState({
      data: currentData,
      config: currentConfig
    });
  };

  const handleCheckBoxChange = key => event => {
    const currentStatus = {
      ...checked,
      [key]: event.target.checked
    };

    setChecked(currentStatus);
    updateChart(currentStatus);
    const atLeastOneCheckboxActive = Object.values(currentStatus).reduce((acc, val) => acc || val, false);
    setAccordionOpen(atLeastOneCheckboxActive);
  }

  React.useEffect(() => {
    updateChart(checked);
  }, [data]);

  return (
    <div>
      <Accordion
        defaultExpanded={true}
        expanded={accordionOpen}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions3-content"
          id="additional-actions-header"
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
            <Grid container>
              {data.map((elem, index) => (
                  <Grid item xs={12} md={6} key={elem.key}>
                    <FormControlLabel
                      control={getCheckbox(index, checked[elem.key])}
                      label={elem.label}
                      className={classes.checkboxLabel}
                      onChange={handleCheckBoxChange(elem.key)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Grid>
              )
              )}
            </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ChartistGraph
                style={{ marginTop: '30px' }}
                data={chartState.data}
                type={type}
                options={chartState.config.options}
                listener={chartState.config.animation}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

StatsChart.propTypes = {
  labels: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  config: PropTypes.shape({
    settings: PropTypes.any,
    options: PropTypes.any,
    animation: PropTypes.any
  })
}
