import React from 'react';
import PropTypes from 'prop-types';

import MoreIcon from '@material-ui/icons/MoreHoriz';

import DropdownButton from 'components/DropdownButton';

const MenuButton = ({ menu }) =>
  <DropdownButton
    menu={menu}
    ButtonIcon={MoreIcon}
  />;

MenuButton.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    onClick: PropTypes.func,
    text: PropTypes.string
  }))
};

MenuButton.defaultProps = {
  menu: []
};

export default MenuButton;
