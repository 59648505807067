import { fetchMe } from 'api';
import { ADD_USER } from 'store/reducer/actions';

// ------------------------------------
// Actions
// ------------------------------------
export const loadUser = () => ({
  type: ADD_USER,
  payload: fetchMe()
});

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  user: {}
};

export function handleUserFetched(state = initialState, action) {
  if (action.type === ADD_USER) {
    const userData = action.payload.data;
    if (userData) {
      return {
        ...state,
        user: {
          avatar: userData.name.toUpperCase().charAt(0) + userData.surname.toUpperCase().charAt(0),
          isAdmin: userData.userType === 'ADMIN',
          ...userData
        }
      };
    }
  }
  return state;
}
