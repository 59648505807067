import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// api
import { fetchExternalServiceById, modifyExternalService } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import ExternalServiceForm from 'views/BackOffice/ExternalServices/components/ExternalServiceForm';

export default function ModifyExternalService({ history }) {
  const { id } = useParams();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
              <ExternalServiceForm
                retrieveService={() => fetchExternalServiceById(id).then(res => res.data)}
                disableServiceName={true}
                history={history}
                headerMsg={'Modify External Service'}
                btnAction={modifyExternalService}
                btnProps={{
                  btnText: 'Modify External Service',
                  successMsg: 'External Service modified',
                  failureMsg: 'Unable to modify external service'
                }}
              />
        </GridItem>
      </GridContainer>
    </div>
  );
}

ModifyExternalService.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}
