import { connect } from 'react-redux';

import { loadUser } from 'store/reducer/userReducer';
import { loadMessages, updateForChat } from 'store/reducer/messagesReducer';

import Dashboard from 'layouts/Dashboard';

// read from redux store
const mapStateToProps = (state) => ({
  liveChats: state.messages.liveChat,
  currentHotelId: state.hotel
});

// dispatch actions to redux store
const mapDispatchToProps = {
  loadUser,
  updateForChat,
  loadMessages
};

// container component/smart component (interacts with redux)
const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
