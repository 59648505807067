import React from 'react';

import logo from 'logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import brandStyle from './style';

const useStyles = makeStyles(brandStyle);

const Brand = () => {
  const classes = useStyles();
  return (
  <div className={classes.logo}>
    <p className={classes.logoLink}>
      <img src={logo} />
    </p>
  </div>
  )
};

export default Brand;
