import React from 'react';
// api
import { createUser } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import UserForm from 'views/BackOffice/Users/components/UserForm';

export default function CreateUser({ history }) {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <UserForm
            history={history}
            headerMsg={'Create Profile'}
            btnAction={createUser}
            btnProps={{
              btnText: 'Create User',
              successMsg: 'User Created',
              failureMsg: 'Unable to create a new user'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

CreateUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}
