import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import initStore from 'store';

// core components
import Dashboard from 'containers/DashboardContainer';
import BackOffice from 'containers/BackofficeContainer';
import ErrorReservation from 'layouts/ErrorReservation';
import SignIn from 'views/Login/SignIn';
import ForgotPassword from 'views/Login/ForgotPassword';
import ResetPassword from 'views/Login/ResetPassword';

import 'chartist/dist/chartist.min.css';
import 'assets/css/material-dashboard-react.css?v=1.8.0';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const store = initStore();
const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/signin" component={SignIn} exact={true}/>
        <Route path="/signin/password" component={ForgotPassword} exact={true}/>
        <Route path="/signin/reset" component={ResetPassword} exact={true}/>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/backoffice" component={BackOffice} />
        <Route path="/error/reservation" component={ErrorReservation} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
    <ReduxToastr
      timeOut={5000}
      newestOnTop={false}
      preventDuplicates
      position='top-right'
      transitionIn='fadeIn'
      transitionOut='fadeOut'
      progressBar={false}
    />
  </Provider>
  ,
  document.getElementById('root')
);
