import React from 'react';
import PropTypes from 'prop-types';

import { FormLabel } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CustomAutocomplete from 'components/CustomAutocomplete';
import TimeSlot from 'components/TimeSlot';

const timeslotDropdown = [
  { value: 'ALWAYS', text: 'Always' },
  { value: 'TIME_SLOTS', text: 'Set Live Chat availability' }
];

const defaultTimeslots = [
  { dayOfTheWeek: 'MONDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'TUESDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'WEDNESDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'THURSDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'FRIDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'SATURDAY', startTime: '08:00:00', stopTime: '17:00:00' },
  { dayOfTheWeek: 'SUNDAY', startTime: '08:00:00', stopTime: '17:00:00' }
];

const Index = ({ liveChatAvailabilityType, liveChatTimeSlotList, handleBeChanges }) => {
  const handleLiveChatAvailability = (liveChatAvailability) => {
    handleBeChanges({
      liveChatAvailabilityType: liveChatAvailability,
      liveChatTimeSlotList: liveChatAvailability === 'TIME_SLOTS' ? defaultTimeslots : []
    })
  }

  const handleTimeslotUpdate = (key, timeSlot) => {
    const currentTimeslots = liveChatTimeSlotList;
    if (!timeSlot) {
      // I must remove this timeslot
      const timeslot = currentTimeslots.filter(e => e.dayOfTheWeek !== key);
      handleBeChanges({ liveChatTimeSlotList: timeslot })
    } else {
      const index = currentTimeslots.findIndex(elem => elem.dayOfTheWeek === key);
      if (index !== -1)
        currentTimeslots[index] = timeSlot;
      else
        currentTimeslots.push(timeSlot);

      handleBeChanges({ liveChatTimeSlotList: currentTimeslots })
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <CustomAutocomplete
        value={liveChatAvailabilityType}
        labelText={'Live Chat enabled'}
        required={true}
        options={timeslotDropdown.map(t => t.value).concat([''])}
        getOptionLabel={option => { const v = timeslotDropdown.find(t => t.value === option); return v ? v.text : ''; }}
        onChange={handleLiveChatAvailability}
      />
    </GridItem>
        { liveChatAvailabilityType === 'TIME_SLOTS'
          ? <div>
        <GridItem xs={12} sm={12} md={12}>
          <p>
            <FormLabel
              required
              style={{ color: '#A9AEBC' }}
            >
              Live Chat Availability configuration
            </FormLabel>
          </p>
        </GridItem>
           <GridItem xs={12} sm={12} md={12}>
            <div style={{
              border: '1px solid #A9AEBC',
              borderRadius: '5px'
            }}>
              <TimeSlot dayLabel={'Monday'} dayOfTheWeek={'MONDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Tuesday'} dayOfTheWeek={'TUESDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Wednesday'} dayOfTheWeek={'WEDNESDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Thursday'} dayOfTheWeek={'THURSDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Friday'} dayOfTheWeek={'FRIDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Saturday'} dayOfTheWeek={'SATURDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
              <TimeSlot dayLabel={'Sunday'} dayOfTheWeek={'SUNDAY'} timeslots={liveChatTimeSlotList} onChange={handleTimeslotUpdate}/>
            </div>
          </GridItem>
          </div>
          : null
        }
    </GridContainer>
    </div>
  );
}

Index.propTypes = {
  liveChatAvailabilityType: PropTypes.string,
  liveChatTimeSlotList: PropTypes.array,
  handleBeChanges: PropTypes.func
}

export default Index;
