import React from 'react';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

export default function PerfectScrollView ({ startAtEnd, children, ...rest }) {
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  let ps;

  const resizeFunction = () => {
    if (ps)
      ps.update();
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('resize', resizeFunction);
      if (navigator.platform.indexOf('Win') > -1 && ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, [mainPanel]);

  React.useEffect(() => {
    if (mainPanel && mainPanel.current)
      mainPanel.current.scrollTop = startAtEnd ? Number.MAX_SAFE_INTEGER : 0;
  }, [children]);

  return (
    <div ref={mainPanel} {...rest} style={{ position: 'relative', height: '100%', overflow: 'auto' }}>
      {children}
    </div>
  );
}

PerfectScrollView.defaultProps = {
  startAtEnd: false
};

PerfectScrollView.propTypes = {
  startAtEnd: PropTypes.bool,
  children: PropTypes.node
};
