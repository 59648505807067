import { connect } from 'react-redux';

import TransactionStats from '../components/TransactionStats';

const mapStateToProps = (state) => ({
  labels: state.statisticsData.transaction.labels,
  allSeries: state.statisticsData.transaction.values
});

// container component/smart component (interacts with redux)
const TransactionStatsContainer = connect(
  mapStateToProps,
  null
)(TransactionStats);

export default TransactionStatsContainer;
