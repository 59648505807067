import { createStore, applyMiddleware, compose } from 'redux';
import PromiseMiddleware from 'redux-promise';

// import alertMiddleware from './middleware/connectionAlert';
import initApplicationState from './init';
import makeRootReducer from './reducer';

const composeEnhancers =
  typeof window === 'object' &&
    /* eslint no-underscore-dangle:0 */
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    /* eslint no-underscore-dangle:0 */
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    PromiseMiddleware
    //    alertMiddleware
  ];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    enhancer
  );

  initApplicationState(initialState, store);

  return store;
};
