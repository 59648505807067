import { ALL_STATISTICS } from '../actions';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  summary: {
    value: 0,
    change: 0
  },
  labels: [],
  values: {
    phoneCallsGenerated: [],
    liveChatGenerated: [],
    miceWeddingRequests: [],
    packageDealsRequests: []
  }
};

export function leadStatisticsReducer(state = initialState, action) {
  if (!action || !action.payload || !action.payload[1])
    return state;

  if (action.type === ALL_STATISTICS) {
    const leadStatistics = action.payload[1].data;
    return {
      summary: leadStatistics.summary,
      labels: leadStatistics.labels,
      values: {
        phoneCallsGenerated: leadStatistics.phoneCallsGenerated,
        liveChatGenerated: leadStatistics.liveChatGenerated,
        miceWeddingRequests: leadStatistics.miceWeddingRequests,
        packageDealsRequests: leadStatistics.packagesDealsRequests
      }
    }
  }

  return state;
}

export default leadStatisticsReducer;
