import React from 'react';
import PropTypes from 'prop-types';

// api
import { createHotel, getHotelByInstallationName } from 'api';

// components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';

// view components
import HotelFormBody from 'views/BackOffice/Hotels/components/HotelFormBody';
import HotelFormHeader from 'views/BackOffice/Hotels/components/HotelFormHeader';
import { isHotelMailValidOrEmpty } from '../utils';

const CreateHotel = ({ history }) => {
  const [form, setForm] = React.useState({
    id: -1,
    chatbotId: '',
    installationName: '',
    mailCc: '',
    mailCcn: '',
    mailTo: '',
    name: '',
    phoneNumber: '',
    bookingEngineType: '',
    bookingEngineConfig: '',
    whatsappNumber: '',
    whatsappNlpActive: false,
    timezone: 'Europe/Rome',
    isoCountryCode: 'EN',
    liveChatAvailabilityType: 'ALWAYS',
    liveChatTimeSlotList: [],
    facebookAccessToken: ''
  });
  const [formValidation, setFormValidation] = React.useState({});

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.id]: event.target.value
    });
  };

  const handleBeChanges = bookingEngineInfos => {
    setForm({
      ...form,
      ...bookingEngineInfos
    });
  };

  const handleHotelCreation = () => {
    return createHotel(form).then(() => history.goBack());
  };

  React.useEffect(() => {
    let installationName;
    if (form.installationName) {
      getHotelByInstallationName(form.installationName).then(res => {
        if (res.status === 200) {
          installationName = {
            error: true,
            text: 'already used'
          }
        }
      }).finally(() => {
        setFormValidation({
          ...formValidation,
          installationName
        });
      });
    } else {
      setFormValidation({
        ...formValidation,
        installationName
      });
    }
  }, [form.installationName]);

  const validateMailField = (value, formName) => {
    let fieldValidation;

    if (!isHotelMailValidOrEmpty(value)) {
      fieldValidation = {
        error: true,
        text: 'Invalid mail format'
      }
    }

    setFormValidation({
      ...formValidation,
      [formName]: fieldValidation
    });
  }

  React.useEffect(() => {
    validateMailField(form.mailTo, 'mailTo');
  }, [form.mailTo]);

  React.useEffect(() => {
    validateMailField(form.mailCc, 'mailCc');
  }, [form.mailCc]);

  React.useEffect(() => {
    validateMailField(form.mailCcn, 'mailCcn');
  }, [form.mailCcn]);

  const errorInFields = (formValidation &&
    ((formValidation.installationName && formValidation.installationName.error) ||
      (formValidation.mailTo && formValidation.mailTo.error) ||
      (formValidation.mailCc && formValidation.mailCc.error) ||
      (formValidation.mailCcn && formValidation.mailCcn.error))
  );

  const disableSubmit =
    !form.installationName ||
    !form.name ||
    !form.mailTo ||
    !form.bookingEngineType ||
    !form.timezone ||
    !form.isoCountryCode ||
    !form.liveChatAvailabilityType ||
    !!errorInFields ||
    Object.values(JSON.parse(form.bookingEngineConfig)).some(x => !x);

  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <HotelFormHeader title='Create Hotel' subtitle='Complete hotel information'/>
            <HotelFormBody
              form={form}
              formValidation={formValidation}
              handleChange={handleChange}
              handleBeChanges={handleBeChanges}
            />
            <CardFooter>
              <Button
                color='info'
                onClick={() => {
                  history.goBack()
                }}
              >
                Back
              </Button>
              <SpinnerButton
                onClick={handleHotelCreation}
                disabled={disableSubmit}
                btnText='Create Hotel'
                successMsg='Hotel Created'
                failureMsg='Unable to create a new hotel'
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default CreateHotel;

CreateHotel.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
};
