import { combineReducers } from 'redux';
import { fetchConversationStatistics, fetchLeadStatistics, fetchTransactionStatistics, fetchFeedbackStatistics } from 'api';

import filters from './statistics/filterReducer';
import conversation from './statistics/conversationReducer';
import leadGeneration from './statistics/leadStatisticsReducer';
import transaction from './statistics/transactionReducer';
import feedback from './statistics/feedbackReducer';

import { ALL_STATISTICS } from './actions';

// ------------------------------------
// Actions Creators
// ------------------------------------
export { updateFilter } from './statistics/filterReducer';

export const loadAllStatistics = (dateFrom, dateTo, hotels, timeAggregation) => ({
  type: ALL_STATISTICS,
  payload: Promise.all([
    fetchConversationStatistics(dateFrom, dateTo, hotels, timeAggregation),
    fetchLeadStatistics(dateFrom, dateTo, hotels, timeAggregation),
    fetchTransactionStatistics(dateFrom, dateTo, hotels, timeAggregation),
    fetchFeedbackStatistics(dateFrom, dateTo, hotels, timeAggregation)
  ]).catch((err) => {
    console.error('Error making request', err);
    return []
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const configReducer = combineReducers({
  filters,
  conversation,
  leadGeneration,
  transaction,
  feedback
});

export default configReducer;
