import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';

// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomInput (props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    errorMessage,
    success,
    required
  } = props;

  const labelClasses = classNames(
    classes.labelRoot,
    {
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error
    }
  );

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined
        ? (
          // focused={false} hack disable default label style
        <InputLabel
          className={labelClasses}
          htmlFor={id}
          {...labelProps}
          focused={false}
          required={required}
          classes={{
            asterisk: classes.asterisk
          }}
        >
          {labelText}
        </InputLabel>
          )
        : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        aria-describedby={`${id}-error-helper`}
        {...inputProps}
      />
      { error && errorMessage
        ? <FormHelperText id={`${id}-error-helper`} className={classes.labelRootError}>{errorMessage}</FormHelperText>
        : null}
      {error
        ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
          )
        : success
          ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
            )
          : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  required: PropTypes.bool
};
