import { connect } from 'react-redux';
import MessagesComponent from './component';

import { messageRead, updateForChat } from 'store/reducer/messagesReducer';

// read from redux store
const mapStateToProps = (state) => ({
  liveChats: state.messages.liveChat,
  currentHotelId: state.hotel
});

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  updateForChat: message => dispatch(updateForChat(message)),
  messageRead: chat => dispatch(messageRead(chat))
});

// container component/smart component (interacts with redux)
const MessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesComponent);

export default MessagesContainer;
