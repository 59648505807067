import React from 'react';
import PropTypes from 'prop-types';

import { chartOptions } from 'variables/charts.js';
import StatsChart from './StatsChart';

export default function LeadGenerationStats({ labels, allSeries }) {
  const chartData = [
    { key: 'liveChatGenerated', label: 'Livechat generated', data: allSeries.liveChatGenerated },
    { key: 'phoneCallsGenerated', label: 'Phone calls generated', data: allSeries.phoneCallsGenerated },
    { key: 'miceWeddingRequests', label: 'Mice/wedding requests', data: allSeries.miceWeddingRequests },
    { key: 'packageDealsRequests', label: 'Package/Deals requests', data: allSeries.packageDealsRequests }
  ];

  return (
    <div>
      <StatsChart labels={labels} config={chartOptions} data={chartData}/>
    </div>
  );
}

LeadGenerationStats.propTypes = {
  labels: PropTypes.any,
  allSeries: PropTypes.shape({
    phoneCallsGenerated: PropTypes.any,
    liveChatGenerated: PropTypes.any,
    miceWeddingRequests: PropTypes.any,
    packageDealsRequests: PropTypes.any
  })
}
