import { connect } from 'react-redux';
import UserListTable from './component';
import {
  loadUserAndHotelList as loadUserAndHotelListApi
} from '../../../../store/reducer/backofficeReducer';

const mapStateToProps = (state) => ({
  userList: state.backofficeData.userList,
  hotelList: state.backofficeData.hotelList
});

const mapDispatchToProps = (dispatch) => ({
  loadUserAndHotelList: () => loadUserAndHotelListApi(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserListTable);
