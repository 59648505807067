import React from 'react';

import Card from 'components/Card/Card';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import logo from 'logo.svg';

export default function ErrorReservation() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}/>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader>
              <p style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo} />
              </p>
            </CardHeader>
            <CardBody>
              <p style={{ display: 'flex', justifyContent: 'center' }}>
                An error occurred while preparing your reservation, please retry.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
