import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import { parseISO, add, format, isWithinInterval, addYears, isBefore } from 'date-fns';
import classNames from 'classnames';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import SortedTable from 'components/Tables/SortedTable';
import TTHDatePicker from 'components/TTHDatePicker/TTHDatePicker';
import TTHSearchField from 'components/TTHSearchField';
import { dateComparator } from 'utils/comparators';
import { formatMessageTimestampDistance } from 'utils/formatters';

const useStyle = makeStyles(styles);

const ReservationComponent = ({ tableData, onRefresh, currentHotelId }) => {
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [fullTextSearch, setFullTextSearch] = React.useState();

  React.useEffect(() => { onRefresh(currentHotelId) }, [onRefresh, currentHotelId]);

  const handleSearchChange = (event) => {
    setFullTextSearch(event.target.value);
  }

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date)
    if (date && selectedDateTo && date.getTime() > selectedDateTo.getTime()) setSelectedDateFrom(selectedDateTo)
  };

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date)
    if (date && selectedDateFrom && date.getTime() < selectedDateFrom.getTime()) setSelectedDateTo(selectedDateFrom)
  };

  const setMinMaxDate = (dateFromTo) => dateFromTo || new Date();

  const classes = useStyle();

  const filterReservations = dto => {
    let interval = null;
    if (selectedDateFrom || selectedDateTo)
      interval = { start: selectedDateFrom || new Date(1), end: selectedDateTo || addYears(new Date(), 1) }
    if (interval && !isBefore(interval.start, interval.end))
      return false
    return (!interval || !dto.bookingDate || !isBefore(interval.start, interval.end) || isWithinInterval(parseISO(dto.bookingDate), interval)) &&
      (!fullTextSearch || JSON.stringify(dto).toLocaleLowerCase().includes(fullTextSearch.toLocaleLowerCase()))
  };

  const stringComparator = (a, b, numericStr = false) =>
    (a ? a.localeCompare(b, undefined, { numeric: numericStr }) : (b ? 1 : 0));

  const stringNumComparator = (a, b) => (stringComparator(a, b, true));

  const tableHeaderData = [
    { id: 'bookingReference', text: 'Booking ID', sortable: true, comparator: stringNumComparator },
    { id: 'bookingDate', text: 'Booking Time', sortable: true, comparator: dateComparator, render: dto => formatMessageTimestampDistance(dto.bookingDate) },
    { id: 'name', text: 'Name', sortable: true, comparator: stringComparator },
    { id: 'checkInDate', text: 'Arrival', sortable: true, comparator: dateComparator, render: dto => formatDate(dto.checkInDate) },
    { id: 'checkoutDate', text: 'Departure', sortable: true, comparator: dateComparator, render: dto => calcCheckoutDate(dto.checkInDate, dto.nights) },
    { id: 'guests', text: 'Guests', sortable: true, comparator: stringNumComparator },
    { id: 'formula', text: 'Formula', sortable: true, comparator: stringComparator },
    { id: 'policy', text: 'Policy', sortable: true, comparator: stringComparator },
    {
      id: 'status',
      text: 'Status',
      sortable: true,
      comparator: stringComparator,
      className: dto => dto.status === 'COMPLETE' ? classes.successText : classes.warningText,
      render: dto => translateStatus(dto.status)
    },
    {
      id: 'amount',
      text: 'Price',
      sortable: true,
      comparator: stringNumComparator,
      className: classes.bold,
      render: dto => formatAmount(dto.amount, dto.currency)
    },
    {
      id: 'action',
      text: 'Action',
      sortable: false,
      comparator: undefined,
      className: classes.bold,
      render: ActionButton
    }
  ];

  const filteredData = tableData.filter(filterReservations);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classNames(classes.card, classes.noMargin)}>
          <CardBody className={classes.cardBody}>
            <Grid container>
              <Grid item xs={6} sm={6} md={2} className={classNames(classes.searchContainer, classes.fieldContainer)}>
                <div className={classes.label}>Search</div>
                <TTHSearchField onChange={handleSearchChange} />
              </Grid>
              <Grid item xs={6} sm={6} md={10} className={classes.fieldContainer}>
                <div className={classes.label}>Choose a period of time:</div>
                <Grid container direction='row' alignItems='center'>
                  <TTHDatePicker
                    value={selectedDateFrom}
                    onChange={handleDateChangeFrom}
                    maxDate={setMinMaxDate(selectedDateTo)}
                  />
                  <div className={classes.label} style={{ margin: '10px' }}><span>to</span></div>
                  <TTHDatePicker
                    value={selectedDateTo}
                    onChange={handleDateChangeTo}
                    minDate={selectedDateFrom}
                    disableFuture={true}
                  />
                  <IconButton style={{ marginLeft: 'auto' }} onClick={onRefresh}>
                    <RefreshIcon fontSize={'large'}/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <SortedTable
              tableData={filteredData}
              tableHeaderData={tableHeaderData}
              orderDirection={{ columnId: 'bookingReference', dir: 'asc' }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ReservationComponent.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    bookingReference: PropTypes.string,
    bookingDate: PropTypes.string,
    name: PropTypes.string,
    checkInDate: PropTypes.string,
    nights: PropTypes.number,
    guests: PropTypes.string,
    formula: PropTypes.string,
    policy: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.string,
    currency: PropTypes.string
  })),
  onRefresh: PropTypes.func,
  currentHotelId: PropTypes.number
}

export default ReservationComponent;

const ActionButton = () => <IconButton><MoreIcon /></IconButton>;

const calcCheckoutDate = (str, days) => {
  const base = parseISO(str.substring(0, str.indexOf('T')));
  return format(add(base, { days: days }), 'dd/MM/yyyy');
}

const formatDate = (str) => {
  if (!str)
    return '';

  const truncated = parseISO(str.substring(0, str.indexOf('T')));
  return format(truncated, 'dd/MM/yyyy');
};

const translateStatus = (str) => {
  return str === 'COMPLETE' ? 'Paid' : 'Unpaid';
};

const formatAmount = (value, currency) => {
  if (!value || !currency)
    return '';

  const num = parseFloat(value);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  });
  return formatter.format(num);
}
