// @material-ui/icons
import { People, SettingsApplications, Tune } from '@material-ui/icons';
import Person from '@material-ui/icons/Person';
import HotelIcon from '@material-ui/icons/Hotel';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ServiceIcon from '@material-ui/icons/SettingsApplications';
import BadgeIconContainer from './components/BadgeIcon/badgeIconContainer';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard';
import Reservation from 'views/Reservation';
import Messages from 'views/Messages';
import UserConfigurationContainer from 'views/Configuration';
import HoteList from 'views/BackOffice/Hotels/HoteList';
import CreateHotel from 'views/BackOffice/Hotels/CreateHotel';
import ModifyHotel from 'views/BackOffice/Hotels/ModifyHotel';
import ListUsers from 'views/BackOffice/Users/UsersList';
import CreateUser from 'views/BackOffice/Users/CreateUser';
import ModifyUser from 'views/BackOffice/Users/ModifyUser';
import ChangeUserPassword from 'views/BackOffice/Users/ChangeUserPassword';
import ExternalServicesList from 'views/BackOffice/ExternalServices/ExternalServicesList';
import CreateExternalService from 'views/BackOffice/ExternalServices/CreateExternalService';
import ModifyExternalService from 'views/BackOffice/ExternalServices/ModifyExternalService';

export const dashboardRoutes = [
  {
    path: '/messages',
    name: 'Messages',
    icon: BadgeIconContainer,
    component: Messages,
    layout: '/dashboard'
  },
  {
    path: '/home',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardPage,
    layout: '/dashboard'
  },
  {
    path: '/bookings',
    name: 'Bookings',
    icon: HotelIcon,
    component: Reservation,
    layout: '/dashboard'
  },
  {
    path: '/configuration',
    name: 'Settings',
    icon: Tune,
    component: UserConfigurationContainer,
    layout: '/dashboard'
  },
  {
    path: '/backoffice',
    name: 'Backoffice',
    icon: SettingsApplications,
    layout: '/backoffice',
    footer: true
  }
];

export const backofficeRoutes = [
  {
    path: '/users/create',
    name: 'Users',
    icon: Person,
    component: CreateUser,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/users/modify/:id',
    name: 'Users',
    icon: Person,
    component: ModifyUser,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/users/modify/:id/password',
    name: 'Users',
    icon: Person,
    component: ChangeUserPassword,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/hotels',
    name: 'Hotels',
    icon: HotelIcon,
    component: HoteList,
    layout: '/backoffice',
    sidebar: true
  },
  {
    path: '/hotels/create',
    name: 'Create Hotel',
    icon: HotelIcon,
    component: CreateHotel,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/hotels/modify/:id',
    name: 'Modify Hotel',
    icon: HotelIcon,
    component: ModifyHotel,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/users',
    name: 'Users',
    icon: People,
    component: ListUsers,
    layout: '/backoffice',
    sidebar: true
  },
  {
    path: '/externalservices',
    name: 'External Services',
    icon: ServiceIcon,
    component: ExternalServicesList,
    layout: '/backoffice',
    sidebar: true
  },
  {
    path: '/externalservices/create',
    name: 'Create External Service',
    icon: ServiceIcon,
    component: CreateExternalService,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/externalservices/modify/:id',
    name: 'Modify External Service',
    icon: ServiceIcon,
    component: ModifyExternalService,
    layout: '/backoffice',
    sidebar: false
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    layout: '/dashboard',
    sidebar: true,
    footer: true
  }
];
