import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import logo from 'logo.svg';
import 'assets/css/signin.css';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    backgroundColor: '#FFF'
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  label: {
    fontSize: '16px'
  },
  link: {
    textDecoration: 'none',
    color: '#3f51b5',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em !important'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  // This is to redirect to /dashboard at each login
  document.cookie = 'quarkus-redirect-location=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

  return (
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src={logo}/>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate action="j_security_check" method="post">
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="j_username"
              autoComplete="email"
              autoFocus
              classes={{
                root: 'login-override'
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="j_password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              classes={{
                root: 'login-override'
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              muiClasses={{ label: classes.label }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to='signin/password' className={classes.link}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
