import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import {
  grayBackgroundColor,
  grayBorderColor,
  whiteColor
} from '../../assets/jss/material-dashboard-react';

const messagesStyle = {
  root: {
    backgroundColor: grayBackgroundColor,
    borderRadius: '6px',
    width: '100%',
    height: '49px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    paddingTop: '2px',
    paddingLeft: '20px'
  },
  noPaddingRight: {
    paddingRight: '0 !important'
  },
  noPaddingLeft: {
    paddingLeft: '0 !important'
  },
  detailSection: {
    backgroundColor: grayBackgroundColor,
    padding: '30px 20px'
  },
  listItem: {
    height: '110px',
    background: `linear-gradient(180deg, ${grayBackgroundColor} 60%, ${grayBorderColor} 100%)`,
    padding: '20px',
    paddingTop: '16px',
    cursor: 'pointer',
    flexWrap: 'nowrap'
  },
  firstMessage: {
    marginTop: '0px',
    borderTopRightRadius: '10px',
    padding: '20px',
    paddingTop: '16px'
  },
  lastMessage: {
    borderBottomRightRadius: '10px',
    borderColor: whiteColor,
    padding: '20px',
    paddingTop: '16px'
  },
  nameAndTimestamp: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  messageDetails: {
    paddingLeft: '15px',
    flex: '1 1 auto',
    maxWidth: 'calc(100% - 55px)' // 40px avatar + 15px padding
  },
  messageAvatar: {
    flex: '0 0 auto',
    minWidth: '40px'
  },
  sender: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  timestamp: {
    borderRadius: '7px',
    fontSize: '11px',
    backgroundColor: whiteColor,
    paddingLeft: '10px',
    paddingRight: '10px',
    textTransform: 'uppercase'
  },
  preview: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  selectedMessage: {
    background: 'linear-gradient(270deg, #ffffff 98.5%, #427AF5 98.5%, #427AF5 100%)'
  },
  chatContainer: {
    paddingTop: '0px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  chatAvatar: {
    width: '64px',
    height: '64px',
    flex: '0 0 auto'
  },
  chatMessageBox: {
    flex: '1 1 auto',
    padding: '20px',
    backgroundColor: grayBackgroundColor,
    borderRadius: '12px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  messageSentDateTime: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.38)',
    textAlign: 'end'
  },
  chatDetailAvatar: {
    width: '105px',
    height: '105px',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  chatDetailSenderName: {
    marginTop: '10px',
    fontSize: '20px',
    fontWeight: 500,
    color: '#1d51c2'
  },
  chatDetailContactData: {
    marginTop: '17px',
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'uppercase',
    fontWeight: 'normal'
  },
  chatDetailContactDataValue: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 500,
    textTransform: 'none'
  },
  sendButton: {
    color: 'rgba(0, 0, 0, 0.38)',
    '&:hover': {
      background: 'none'
    }
  },
  chatHeight: {
    height: 'calc(100vh - 250px)'
  },
  noBackground: {
    background: 'unset'
  },
  chatTimestampWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  chatDeliverStatus: {
    marginLeft: '3px',
    fontSize: '15px',
    verticalAlign: 'text-bottom'
  },
  ...styles
}

export default messagesStyle
