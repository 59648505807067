import React from 'react';
import PropTypes from 'prop-types';

import { ButtonGroup, Button } from '@material-ui/core';

const CustomButtonGroup = ({ items, selected, onChange }) => {
  return (
    <ButtonGroup size="large" aria-label="large outlined primary button group" >
      {
        items.map((item, index) =>
          <Button
            key={`item-${item}`}
            style={{
              color: '#999',
              borderColor: '#999',
              backgroundColor: index === selected ? 'rgba(153, 153, 153, 0.33)' : 'unset'
            }}
            onClick={() => {
              onChange(index);
            }}
          >
            {item}
          </Button>
        )
      }
    </ButtonGroup>
  );
};

CustomButtonGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.number,
  onChange: PropTypes.func
};

CustomButtonGroup.defaultProps = {
  items: [],
  selected: -1,
  onChange: () => {}
};

export default CustomButtonGroup;
