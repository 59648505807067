import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

// core components
import Brand from 'components/Brand';
import Links from './Links';

import styles from './sidebarStyle.js';

const useStyles = makeStyles(styles);

const FooterLinks = ({ footer, isAdminUser }) =>
  isAdminUser
    ? <div><Links routes={footer} /></div>
    : null

FooterLinks.propTypes = {
  footer: PropTypes.arrayOf(PropTypes.object),
  isAdminUser: PropTypes.bool
};

export default function Sidebar({ open, handleDrawerToggle, routes, isAdminUser }) {
  const classes = useStyles();

  const userLink = routes.filter(r => !r.footer);
  const footer = routes.filter(r => !!r.footer);

  return (
    <>
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <Brand />
          <div className={classes.sidebarMobileWrapper}>
            <Links routes={userLink} />
          </div>
          <FooterLinks footer={footer} isAdminUser={isAdminUser}/>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          anchor='left'
          variant='permanent'
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          <Brand />
          <div className={classes.sidebarWrapper}>
            <Links routes={userLink} />
          </div>
          <FooterLinks footer={footer} isAdminUser={isAdminUser}/>
        </Drawer>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  isAdminUser: PropTypes.bool
};
