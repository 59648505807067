import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';

// core components
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import SortedTable from 'components/Tables/SortedTable';
import TTHSearchField from 'components/TTHSearchField';
import MenuButton from 'components/MenuButton';

import { hotelStyle } from './hotelStyle';

// eslint-disable-next-line react/display-name
const HotelMenuButton = history => dto => <MenuButton menu={[
  {
    id: dto.id,
    onClick: () => {
      history.push('/backoffice/hotels/modify/' + dto.id);
    },
    text: 'Modify'
  }
]}/>;

const stringComparator = (a, b, numericStr = false) =>
  (a ? a.localeCompare(b, undefined, { numeric: numericStr }) : (b ? 1 : 0));

const stringNumComparator = (a, b) => (stringComparator(a, b, true));

const HotelList = ({ hotelList, loadHotelList, history }) => {
  const [fullTextSearch, setFullTextSearch] = React.useState();

  React.useEffect(() => { loadHotelList() }, [loadHotelList]);

  const classes = hotelStyle();
  const HotelMenu = HotelMenuButton(history);

  const tableHeaderData = [
    { id: 'name', text: 'Name', sortable: true, comparator: stringComparator },
    { id: 'installationName', text: 'Installation', sortable: true, comparator: stringNumComparator },
    { id: 'mailTo', text: 'Mail to', sortable: true, comparator: stringComparator },
    { id: 'chatbotId', text: 'Chatbot Id', sortable: true, comparator: stringComparator },
    { id: 'phoneNumber', text: 'Phone', sortable: true, comparator: stringComparator },
    { id: 'action', text: 'Action', sortable: false, comparator: undefined, render: HotelMenu }
  ]

  const handleSearchChange = (event) => {
    setFullTextSearch(event.target.value);
  }

  const filterReservations = dto => {
    return !fullTextSearch || JSON.stringify(dto).toLocaleLowerCase().includes(fullTextSearch.toLocaleLowerCase());
  };

  const filteredData = hotelList.filter(filterReservations);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <Grid container>
              <Grid item xs={6} sm={6} md={4} style={{ padding: '10px 10px 10px 0px' }}>
                <div className={classes.label}>Search</div>
                <TTHSearchField
                  onChange={handleSearchChange}
                  style={{ width: '100%', boxSizing: 'border-box', padding: '10px 20px', height: 'initial' }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={8} style={{
                padding: '10px 0px 10px 10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
              }}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => { history.push('/backoffice/hotels/create') }}
                >
                  Add Hotel
                </Button>
              </Grid>
            </Grid>
            <SortedTable
              tableData={filteredData}
              tableHeaderData={tableHeaderData}
              orderDirection={{ columnId: 'name', dir: 'asc' }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

HotelList.propTypes = {
  hotelList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    installationName: PropTypes.string,
    chatbotId: PropTypes.string,
    mailTo: PropTypes.string,
    phoneNumber: PropTypes.string
  })),
  loadHotelList: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default HotelList;
