import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { format, isAfter, isBefore, isValid } from 'date-fns';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TTHTimePicker from 'components/TTHTimePicker/TTHTimePicker';
import { splitTimeStringInDate } from 'utils/formatters';

const Index = ({ dayLabel, timeslots, dayOfTheWeek, onChange }) => {
  const element = timeslots && timeslots.find(e => e.dayOfTheWeek === dayOfTheWeek);

  const startTime = splitTimeStringInDate(element ? element.startTime : '08:00:00');
  const stopTime = splitTimeStringInDate(element ? element.stopTime : '17:00:00');

  const setTime = (date, key) => {
    onChange(dayOfTheWeek, {
      ...element,
      [key]: isValid(date) ? format(date, 'HH:mm:ss') : '00:00:00'
    });
  }

  const setStartTime = date => {
    setTime(isValid(date) && isAfter(date, stopTime) ? stopTime : date, 'startTime')
  }

  const setStopTime = date => {
    setTime(isValid(date) && isBefore(date, startTime) ? startTime : date, 'stopTime')
  }

  return (
    <div style={{ padding: '10px 0 10px 0' }}>
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex'
            }}>
              <FormControlLabel
                style={{
                  justifySelf: 'center'
                }}
                control={
                  <Checkbox
                    checked={!!element}
                    onChange={() => {
                      if (element)
                        onChange(dayOfTheWeek, null);
                      else
                        onChange(dayOfTheWeek, { dayOfTheWeek: dayOfTheWeek, startTime: '08:00:00', stopTime: '17:00:00' })
                    }}
                    color='primary'
                    disabled={false}
                  />}
                label={dayLabel}
                labelPlacement='end'
                value='end'
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <div style={{
              height: '100%',
              display: 'flex',
              border: '1px solid #A9AEBC',
              borderRadius: '5px'
            }}>
              <TTHTimePicker
                value={startTime}
                disabled={!element}
                onChange={setStartTime}
                style={{
                  justifySelf: 'center',
                  backgroundColor: 'transparent'
                }}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <div style={{
              height: '100%',
              display: 'flex',
              border: '1px solid #A9AEBC',
              borderRadius: '5px'
            }}>
              <TTHTimePicker
                value={stopTime}
                onChange={setStopTime}
                disabled={!element}
                style={{
                  justifySelf: 'center',
                  backgroundColor: 'transparent'
                }}/>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
    </div>
  )
}

Index.propTypes = {
  dayLabel: PropTypes.string,
  dayOfTheWeek: PropTypes.string,
  timeslots: PropTypes.array,
  onChange: PropTypes.func
}

export default Index;
