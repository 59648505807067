import React from 'react';
import PropTypes from 'prop-types';

import { toastr } from 'react-redux-toastr';

// api
import { modifyUserHotelConfig, getUserHotelConfig } from 'api';

// style
import { makeStyles } from '@material-ui/core/styles';

// components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import LiveChatAvailability from 'components/LiveChatAvailability';
import BackdropSpinner from 'components/BackdropSpinner';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,0.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
};

const useStyles = makeStyles(styles);

const ConfigurationHotel = ({ hotelId, history }) => {
  const classes = useStyles();

  const [form, setForm] = React.useState({
    id: hotelId,
    liveChatAvailabilityType: 'ALWAYS',
    liveChatTimeSlotList: []
  });

  const [pristine, setPristine] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleBeChanges = data => {
    setForm({
      ...form,
      ...data
    });
    setPristine(false);
  };

  const handleHotelModification = () => {
    return modifyUserHotelConfig(form)
      .then(() => setPristine(true))
      .catch(() => toastr.error('Failed', 'Error updating hotel'));
  };

  React.useEffect(() => {
    if (hotelId) {
      setLoading(true);
      getUserHotelConfig(hotelId).then(res => {
        setForm({
          ...form,
          ...res.data
        });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        toastr.error('Failed', 'Error retrieving hotel config');
        history.goBack();
      })
    }
  }, [hotelId]);

  const disableSubmit =
    pristine ||
    !form.liveChatAvailabilityType;

  if (loading)
    return <BackdropSpinner/>;

  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>{'Hotel settings'}</h4>
            </CardHeader>
            <CardBody>
              <LiveChatAvailability
                liveChatAvailabilityType={form.liveChatAvailabilityType}
                liveChatTimeSlotList={form.liveChatTimeSlotList}
                handleBeChanges={handleBeChanges}
              />
            </CardBody>
            <CardFooter>
              <SpinnerButton
                onClick={handleHotelModification}
                disabled={disableSubmit}
                btnText='Update Hotel'
                successMsg='Hotel Updated'
                failureMsg='Unable to update hotel'
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default ConfigurationHotel;

ConfigurationHotel.propTypes = {
  hotelId: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
};
