import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomSelect (props) {
  const classes = useStyles();
  const {
    labelText,
    id,
    required,
    onChange,
    menuItems,
    value
  } = props;

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={true}
    >
      {labelText !== undefined
        ? (
        <InputLabel
          className={classes.labelRoot}
          htmlFor={id}
          focused={false}
          required={required}
          classes={{
            asterisk: classes.asterisk
          }}
        >
          {labelText}
        </InputLabel>
          )
        : null}
      <Select
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        className={ classes.underline }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          PaperProps: {
            style: {
              maxHeight: 50 * 6
            }
          },
          getContentAnchorEl: null,
          disableAutoFocusItem: true
        }}
        id={id}
        onChange={(event) => { event.target.id = id; onChange(event); }}
        value={value}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  id: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  })),
  value: PropTypes.any
};
