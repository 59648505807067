import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { Checkbox, FormControlLabel, FormLabel, ImageList, ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchUserByMail } from 'api';

import { dangerColor } from 'assets/jss/material-dashboard-react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import CardFooter from 'components/Card/CardFooter';

import { validateEmail } from 'utils/validators';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  asterisk: {
    color: dangerColor[0]
  }
};

const useStyles = makeStyles(styles);

const defaultUser = { name: '', surname: '', email: '', password: '', hotels: [] };

export default function UserFormComponent({
  history,
  retrieveUser,
  hotelList,
  loadHotelList,
  editablePassword,
  disableMail,
  headerMsg,
  btnAction,
  btnProps
}) {
  const [form, setForm] = React.useState(defaultUser);
  const [showMailError, setShowMailError] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = React.useState([]);
  const [passwordCheck, setPasswordCheck] = React.useState('');
  const [pristine, setPristine] = React.useState(true);
  const classes = useStyles();

  React.useEffect(() => {
    if (disableMail)
      return;

    if (form.email) {
      fetchUserByMail(form.email).then(res => {
        if (res.status === 200)
          setShowMailError(true);
        else if (res.status === 204)
          setShowMailError(false);
      });
    } else
      setShowMailError(false);
  },
  [disableMail, form.email]);

  React.useEffect(() => {
    if (retrieveUser) {
      retrieveUser().then(user => {
        setForm({ ...defaultUser, ...user });
        setSelectedHotel((user && user.hotels) || []);
      }).catch(err => {
        toastr.error('Failed', 'Error loading user data');
        console.error(err);
        history.goBack();
      });
    }
  }, [retrieveUser]);

  React.useEffect(() => {
    loadHotelList();
  }, [loadHotelList]);

  const handleChange = event => {
    setPristine(false);
    if (event.target.id === 'passwordCheck')
      setPasswordCheck(event.target.value);
    else
      setForm({ ...form, [event.target.id]: event.target.value });
  };

  const handleSelectHotel = hotelId => event => {
    setPristine(false);
    const newSelectedHotel = event.target.checked ? [...selectedHotel, { id: hotelId }] : selectedHotel.filter(hotel => hotel.id !== hotelId);
    setSelectedHotel(newSelectedHotel);
    setForm({ ...form, hotels: newSelectedHotel })
  };

  const handleSaveBtn = () => {
    return btnAction(form).then(() => history.goBack());
  };

  const errorPasswordMessage = editablePassword && passwordCheck !== form.password
    ? 'Please make sure your passwords match'
    : null

  const mailError = !!form.email && (!validateEmail(form.email) || showMailError);

  const mailErrorMessage = mailError ? (showMailError ? 'Mail already registered' : 'Invalid email address') : '';

  const disableSubmit = (pristine || !form.name || !form.surname || !form.email || mailError || selectedHotel.length === 0) ||
    (editablePassword && (!passwordCheck || !form.password || form.password !== passwordCheck));

  return (
    <Card>
      <CardHeader color='primary'>
        <h4 className={classes.cardTitleWhite}>{headerMsg}</h4>
        <p className={classes.cardCategoryWhite}>Complete user profile</p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText='First Name'
              id='name'
              required={true}
              inputProps={{
                value: form.name,
                onChange: handleChange
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText='Last Name'
              id='surname'
              required={true}
              inputProps={{
                value: form.surname,
                onChange: handleChange
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText='Email address'
              id='email'
              required={true}
              error={mailError}
              errorMessage={mailErrorMessage}
              inputProps={{
                value: form.email,
                onChange: handleChange,
                disabled: disableMail
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='Password'
              id='password'
              required={true}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'password',
                value: editablePassword ? form.password : 'aaaaaaaa',
                disabled: !editablePassword,
                onChange: handleChange
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='Confirm Password'
              id='passwordCheck'
              required={true}
              errorMessage={errorPasswordMessage}
              error={editablePassword ? passwordCheck !== form.password : false}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'password',
                value: editablePassword ? passwordCheck : 'aaaaaaaa',
                disabled: !editablePassword,
                onChange: handleChange
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <p>
              <FormLabel
                required={true}
                style={{ color: '#A9AEBC' }}
                classes={{
                  asterisk: classes.asterisk
                }}>
                Select hotels
              </FormLabel>
            </p>
          </GridItem>
          <ImageList style={{
            width: '100%',
            height: '112px',
            padding: '0 15px',
            border: '1px solid #A9AEBC',
            borderRadius: '5px'
          }} cols={4}>
            {hotelList.map((hotel) => (
              <ImageListItem key={hotel.id} cols={1} style={{ height: '61px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedHotel.map(hotel => (hotel.id)).indexOf(hotel.id) > -1}
                      onChange={handleSelectHotel(hotel.id)}
                      color='primary'
                    />}
                  label={hotel.name}
                  labelPlacement='end'
                  value='end'
                />
              </ImageListItem>
            ))}
          </ImageList>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          color='info'
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
        <SpinnerButton
          onClick={handleSaveBtn}
          disabled={disableSubmit}
          {...btnProps}
        />
      </CardFooter>
    </Card>
  );
}

UserFormComponent.propTypes = {
  retrieveUser: PropTypes.func,
  hotelList: PropTypes.array,
  loadHotelList: PropTypes.func,
  editablePassword: PropTypes.bool,
  disableMail: PropTypes.bool,
  headerMsg: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  btnAction: PropTypes.func,
  btnProps: PropTypes.any
};

UserFormComponent.defaultProps = {
  editablePassword: true,
  disableMail: false
};
