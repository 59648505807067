import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Button from 'components/CustomButtons/Button';
import NavigationDropdownMenu from 'components/NavigationDropdownMenu';

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';

const useStyles = makeStyles({
  '@global': {
    'button.hotelNavigation span': {
      display: 'inline-block !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  buttonCustom: {
    padding: '12px 20px',
    maxWidth: '250px'
  },
  ...styles
});

const HotelNavigation = ({ hotelList, selectedHotel, changeHotel }) => {
  const classes = useStyles();
  const buttonClassname = hotelId => classNames(
    classes.title,
    classes.fullHeight,
    classes.buttonCustom,
    { [classes.selected]: hotelId === selectedHotel.id },
    'hotelNavigation'
  );
  const barRef = React.useRef();

  const [shownIndex, setShownIndex] = React.useState(-1);

  const handleResize = () => {
    if (barRef.current) {
      const index = computeMaxIndex(barRef.current.children, barRef.current.offsetWidth);
      setShownIndex(index);
    }
  };

  React.useEffect(() => {
    handleResize();
  }, [hotelList])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const showDropdownMenu = shownIndex !== -1;

  let dropdownMenu = [];
  if (showDropdownMenu) {
    dropdownMenu = hotelList.slice(shownIndex).map(h => ({
      id: h.id,
      text: h.name,
      onClick: () => {
        changeHotel(h.id)
      },
      isSelected: selectedHotel.id === h.id
    }));
  }

  return (
    <>
      <div className={classNames(classes.fullHeight, classes.fullWidth, classes.overflowHidden)} ref={barRef}>
        {
          hotelList.map((hotel, idx) =>
            <Button
              color='transparent'
              key={hotel.id}
              className={buttonClassname(hotel.id)}
              onClick={() => {
                changeHotel(hotel.id)
              }}
            >
              {hotel.name}
            </Button>
          )
        }
      </div>
      {showDropdownMenu
        ? <NavigationDropdownMenu menu={dropdownMenu}/>
        : undefined
      }
    </>
  );
};

HotelNavigation.propTypes = {
  hotelList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  selectedHotel: PropTypes.shape({
    id: PropTypes.number
  }),
  changeHotel: PropTypes.func
};

HotelNavigation.defaultProps = {
  hotelList: [],
  selectedHotel: { id: 0 },
  changeHotel: () => {
  }
};

export default HotelNavigation;

const computeMaxIndex = (buttonArray, maxLength) => {
  let usedSpace = 0;
  for (let i = 0; i < buttonArray.length; i++) {
    usedSpace += buttonArray[i].offsetWidth;

    if (usedSpace > maxLength)
      return i;
  }
  return -1;
};
