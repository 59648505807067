/*
  https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
*/
export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateNumber = code => {
  if (code == null)
    return false;

  const re = /^\d+$/;
  return re.test(String(code));
}

export const isMailValidOrEmpty = s => {
  if (!s)
    return true;

  const split = s.split(':');
  let name, mail;

  if (split.length > 1) {
    name = split[0];
    mail = split[1];

    if (!name)
      return false;
  } else
    mail = split[0];

  return validateEmail(mail);
}
