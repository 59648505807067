import React from 'react';
import PropTypes from 'prop-types';

// material-ui components
import Button from 'components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { primaryColor } from '../../assets/jss/material-dashboard-react';
import { toastr } from 'react-redux-toastr';
import CustomButtonGroup from '../CustomButtonGroup';

export default function SpinnerButton({ onClick, disabled, btnText, btnColor, successMsg, failureMsg }) {
  const [progress, setProgress] = React.useState(false);

  const clickAction = () => {
    setProgress(true);
    onClick()
      .then(() => {
        toastr.success('Success', successMsg);
      })
      .catch(() => {
        toastr.error('Failed', failureMsg);
      })
      .finally(() => {
        setProgress(false);
      });
  }

  return (
    <div>
      {
        progress
          ? <CircularProgress style={{ color: primaryColor[0] }}/>
          : <Button
            color={btnColor}
            onClick={clickAction}
            disabled={disabled}
          >
            {btnText}
          </Button>
      }
    </div>
  );
};

SpinnerButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  btnText: PropTypes.string,
  btnColor: PropTypes.string,
  successMsg: PropTypes.string,
  failureMsg: PropTypes.string
};

CustomButtonGroup.defaultProps = {
  btnColor: 'primary'
};
