import { connect } from 'react-redux';

import FeedbackStats from '../components/FeedbackStats';

const mapStateToProps = (state) => ({
  labels: state.statisticsData.feedback.labels,
  allSeries: state.statisticsData.feedback.values
});

// container component/smart component (interacts with redux)
const FeedbackStatsContainer = connect(
  mapStateToProps,
  null
)(FeedbackStats);

export default FeedbackStatsContainer;
