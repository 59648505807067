import { connect } from 'react-redux';
import ExternalServicesListTable from './component';
import {
  loadExternalServiceAndHotelList as loadExternalServiceAndHotelListApi
} from '../../../../store/reducer/backofficeReducer';

const mapStateToProps = (state) => ({
  externalServiceList: state.backofficeData.externalServiceList,
  hotelList: state.backofficeData.hotelList
});

const mapDispatchToProps = (dispatch) => ({
  loadExternalServiceAndHotelList: () => loadExternalServiceAndHotelListApi(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalServicesListTable);
