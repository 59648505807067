import { validateEmail } from 'utils/validators';

export const isHotelMailValidOrEmpty = s => {
  if (!s)
    return true;

  return s.split(',').reduce((acc, val) => acc && validateMailUser(val), true);
}

const validateMailUser = s => {
  const split = s.split(':');
  let name, mail;

  if (split.length > 1) {
    name = split[0];
    mail = split[1];

    if (!name)
      return false;
  } else
    mail = split[0];

  return validateEmail(mail);
}
