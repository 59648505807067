import { addHours, addMinutes, addSeconds, format, formatDistanceStrict, parseISO, startOfDay } from 'date-fns';

export const formatMessageTimestampDistance = (str) => (!str ? '' : formatDistanceStrict(parseISO(str), new Date(), { addSuffix: true }));

export const formatMessageTimestamp = (str) => (!str ? '' : format(parseISO(str), 'dd/MM/yyyy - HH:mm'));

export const splitTimeStringInDate = (str) => {
  const dateSplit = str.split(':');
  const d = { hours: dateSplit[0], minutes: dateSplit[1], seconds: dateSplit[2] };

  return addSeconds(addMinutes(addHours(startOfDay(new Date()), d.hours), d.minutes), d.seconds);
}
