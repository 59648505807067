import { subDays, startOfDay, endOfDay } from 'date-fns';
import { AGGREGATION_TIME_TYPE } from '../types';

// ------------------------------------
// Constants
// ------------------------------------
const UPDATE_FILTER = 'UPDATE_FILTER';

// ------------------------------------
// Actions
// ------------------------------------
export const updateFilter = filter => ({
  type: UPDATE_FILTER,
  payload: filter
});

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  dateFrom: startOfDay(subDays(new Date(), 30)),
  dateTo: endOfDay(new Date()),
  aggregation: AGGREGATION_TIME_TYPE.DAY,
  hotels: []
};

export function filterReducer(state = initialState, action) {
  if (action.type === UPDATE_FILTER)
    return action.payload;

  return state;
}

export default filterReducer;
