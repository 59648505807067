import React from 'react';

import PropTypes from 'prop-types';
// @material-ui/core components
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomAutocomplete ({ value, labelText, required, options, getOptionLabel, onChange }) {
  const classes = useStyles();

  return (
    <Autocomplete
      style={{ marginTop: '27px' }}
      options={options}
      getOptionLabel={getOptionLabel}
      fullWidth={true}
      disablePortal={true}
      ListboxProps={{ style: { maxHeight: '200px' }, position: 'bottom' }}
      renderInput={(params) =>
        <TextField
          {...params}
          label={labelText}
          InputLabelProps={{
            className: classes.labelRoot,
            required: required,
            classes: {
              asterisk: classes.asterisk
            }
          }}
          InputProps={{
            ...params.InputProps,
            className: classes.underline
          }}
        />
      }
      value={value}
      onChange={(event, value) => onChange(value)}
    />
  );
}

CustomAutocomplete.propTypes = {
  value: PropTypes.any,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func
};
