import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
// api
import { changeUserPassword, fetchUserWithId } from 'api';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import FormHeader from 'components/FormHeader';

export default function ChangeUserPassword({ history }) {
  const { id } = useParams();

  const [passwordState, setPasswordState] = React.useState({ password: '', passwordCheck: '' });
  const [user, setUser] = React.useState({ email: '' });

  React.useEffect(() => {
    fetchUserWithId(id).then(res => {
      setUser(res.data);
    }).catch(err => {
      toastr.error('Failed', 'Error loading user data');
      console.error(err);
      history.goBack();
    });
  }, []);

  const handleChange = event => {
    setPasswordState({ ...passwordState, [event.target.id]: event.target.value });
  };

  const handleBtn = () => {
    return changeUserPassword(id, { password: passwordState.password }).then(() => history.goBack());
  }

  const passwordCheckError = passwordState.password !== passwordState.passwordCheck;
  const disableSubmit = !passwordState.password || passwordCheckError;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <FormHeader
              title={'Change user password'}
            />
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText='User'
                    id='user-email'
                    inputProps={{
                      value: user.email,
                      disabled: true
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText='Password'
                    id='password'
                    required={true}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'password',
                      value: passwordState.password,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
              </GridContainer>
                <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText='Confirm Password'
                    id='passwordCheck'
                    required={true}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'password',
                      value: passwordState.passwordCheck,
                      onChange: handleChange,
                      error: passwordCheckError
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                color='info'
                onClick={() => { history.goBack(); }}
              >
                Back
              </Button>
              <SpinnerButton
                onClick={handleBtn}
                disabled={disableSubmit}
                btnText={'Change Password'}
                successMsg={'Password changed'}
                failureMsg={'Unable to change password'}
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ChangeUserPassword.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}
