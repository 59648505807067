import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _find from 'lodash/find';

import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';

import DropdownButton from 'components/DropdownButton';

import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.js';

const useStyles = makeStyles({
  button: {
    color: '#ced0da',
    '&:hover': {
      color: '#3c4858'
    }
  },
  buttonSelected: {
    color: '#B8E5E3'
  },
  ...headerStyle
});

export default function NavigationDropdownMenu({ menu }) {
  const classes = useStyles();

  const buttonClassname = selected => classNames(classes.title, { [classes.selected]: selected });
  const buttonSelected = _find(menu, m => m.isSelected);

  return (
    <DropdownButton
      menu={menu}
      ButtonIcon={ArrowDropDownCircleIcon}
      buttonClasses={buttonSelected ? classes.buttonSelected : classes.button}
      buttonClassname={buttonClassname}
    />
  );
};

NavigationDropdownMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    onClick: PropTypes.func,
    text: PropTypes.string,
    isSelected: PropTypes.bool
  }))
};

NavigationDropdownMenu.defaultProps = {
  menu: []
};
