import { createSelector } from 'reselect';

const hotelList = state => state.identity.user.hotels || [];
const selectedHotelId = state => state.hotel;

const selectedHotelAggregator = (hotels, id) => {
  if (hotels.length === 0)
    return {};

  const filteredHotel = hotels.filter(h => h.id === id);
  if (filteredHotel.length === 0)
    return {}

  return filteredHotel[0];
};

export const selectedHotel = createSelector(
  hotelList,
  selectedHotelId,
  selectedHotelAggregator
);
