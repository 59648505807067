import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Poppers from '@material-ui/core/Popper';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Avatar } from '@material-ui/core';

// core components
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

function AdminNavbarLinks({ user }) {
  const [openProfile, setOpenProfile] = React.useState(null);
  const classes = useStyles();

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target))
      setOpenProfile(null);
    else
      setOpenProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    // FIXME: temporary, until we have a SSO in place
    document.cookie = 'quarkus-credential=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    window.location.reload();
  };

  return (
    <div className={classes.fullHeight}>
      <div className={classNames(classes.manager, classes.fullHeight)}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classNames(classes.buttonLink, classes.fullHeight)}
          style={{ margin: 0 }}
        >
          <div className={classNames(classes.userBox, classes.fullHeight)}>
            <div className={classes.fullHeight}>
              <Avatar aria-label="user" className={classes.avatar}>
                {user.avatar}
              </Avatar>
            </div>
            <div className={classNames(classes.fullHeight, classes.userBoxContent)}>{user.name} {user.surname}</div>
            <div className={classNames(classes.fullHeight, classes.userBoxContent)}><KeyboardArrowDown className={classes.controlIcon} /></div>
          </div>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement='bottom-end'
          className={
            classNames({ [classes.popperClose]: !openProfile }, classes.popperNav)
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string
  })
};

// read from redux store
const mapStateToProps = (state) => ({
  user: state.identity.user
});

// container component/smart component (interacts with redux)
const AdminNavbarLinksContainer = connect(
  mapStateToProps,
  null
)(AdminNavbarLinks);

export default AdminNavbarLinksContainer;
