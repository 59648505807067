import { ALL_STATISTICS } from '../actions';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  summary: {
    value: 0,
    change: 0
  },
  labels: [],
  values: {
    bookingsGenerated: [],
    revenueGenerated: [],
    bookingConversionRate: [],
    ancillariesGenerated: [],
    ancillariesRevenues: [],
    ancillariesConversionRate: []
  }
};

export function transactionReducer(state = initialState, action) {
  if (!action || !action.payload || !action.payload[2])
    return state;

  if (action.type === ALL_STATISTICS) {
    const transactionStatistics = action.payload[2].data;
    return {
      summary: transactionStatistics.summary,
      labels: transactionStatistics.labels,
      values: {
        bookingsGenerated: transactionStatistics.bookingsGenerated,
        revenueGenerated: transactionStatistics.revenueGenerated,
        bookingConversionRate: transactionStatistics.bookingConversionRate,
        ancillariesGenerated: transactionStatistics.ancillariesGenerated,
        ancillariesRevenues: transactionStatistics.ancillariesRevenues,
        ancillariesConversionRate: transactionStatistics.ancillariesConversionRate
      }
    }
  }

  return state;
}

export default transactionReducer;
