import { connect } from 'react-redux';

import { loadUser } from 'store/reducer/userReducer';

import BackOffice from 'layouts/BackOffice';

// dispatch actions to redux store
const mapDispatchToProps = {
  loadUser
};

// container component/smart component (interacts with redux)
const BackofficeContainer = connect(
  null,
  mapDispatchToProps
)(BackOffice);

export default BackofficeContainer;
