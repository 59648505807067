import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

// ##############################
// // // variables used to create animation on charts
// #############################

const axisXLabelParser = (label, index, allLabels) => {
  if (allLabels.length < 35)
    return label;

  if (allLabels.length < 150)
    return index % 7 === 0 ? label : '';

  return label.startsWith('01') ? label : '';
};

const animationOptions = {
  draw: function (data) {
    if (data.type === 'line' || data.type === 'area') {
      data.element.animate({
        d: {
          begin: 600,
          dur: 700,
          from: data.path
            .clone()
            .scale(1, 0)
            .translate(0, data.chartRect.height())
            .stringify(),
          to: data.path.clone().stringify(),
          easing: Chartist.Svg.Easing.easeOutQuint
        }
      });
    }
  }
};

const chartOptionsGenerator = (symbol, ticks, chartType) => ({
  options: {
    showArea: true,
    lineSmooth: false,
    axisX: {
      offset: 48,
      showLabel: false,
      labelInterpolationFnc: axisXLabelParser
    },
    axisY: {
      offset: 48,
      showLabel: false,
      labelInterpolationFnc: val => val + symbol,
      type: chartType,
      ticks: ticks,
      high: ticks ? ticks[ticks.length - 1] : undefined,
      low: ticks ? ticks[0] : undefined
    },
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    seriesBarDistance: 11,
    plugins: [
      ChartistTooltip({
        transformTooltipTextFnc: text => text + symbol
      })
    ],
    height: '200px'
  },
  // for animation
  animation: animationOptions
});

const barChartOptionsGenerator = (symbol, ticks, yAxisType) => ({
  options: {
    stackBars: true,
    axisX: {
      offset: 48,
      showLabel: false,
      labelInterpolationFnc: axisXLabelParser
    },
    axisY: {
      offset: 48,
      showLabel: false,
      labelInterpolationFnc: val => val + symbol,
      type: yAxisType,
      ticks: ticks,
      high: ticks ? ticks[ticks.length - 1] : undefined,
      low: ticks ? ticks[0] : undefined
    },
    plugins: [
      ChartistTooltip({
        transformTooltipTextFnc: text => text + symbol,
        pointClass: 'ct-bar'
      })
    ],
    height: '200px',
    classNames: {
      label: 'ct-label ct-bar-label'
    }
  }
})

export const chartOptions = chartOptionsGenerator('', undefined, undefined);

export const chartOptionsPercentage = chartOptionsGenerator(' %', [0, 20, 40, 60, 80, 100], Chartist.FixedScaleAxis);

export const chartOptionsEuro = chartOptionsGenerator(' €', undefined, undefined);

export const barChartOptions = barChartOptionsGenerator('', undefined, undefined);
