import { connect } from 'react-redux';

import ConversationStats from '../components/ConversationStats';

const mapStateToProps = (state) => ({
  labels: state.statisticsData.conversation.labels,
  allSeries: state.statisticsData.conversation.values
});

// container component/smart component (interacts with redux)
const ConversationStatsContainer = connect(
  mapStateToProps,
  null
)(ConversationStats);

export default ConversationStatsContainer;
