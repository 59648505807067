import { connect } from 'react-redux';

import { loadHotelList as loadHotelListApi } from 'store/reducer/backofficeReducer';

import HotelListComponent from './component';

const mapStateToProps = (state) => ({
  hotelList: state.backofficeData.hotelList
})

const mapDispatchToProps = (dispatch) => ({
  loadHotelList: () => loadHotelListApi(dispatch)
});

const HotelList = connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelListComponent);

export default HotelList;
