import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import Button from 'components/CustomButtons/Button';

import { validateEmail } from 'utils/validators';
import { requireResetLink } from 'api';
import logo from 'logo.svg';

import 'assets/css/signin.css';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    backgroundColor: '#FFF'
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  label: {
    fontSize: '16px'
  },
  link: {
    textDecoration: 'none',
    color: '#3f51b5',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em !important'
  }
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [mail, setMail] = React.useState('');
  const [sent, setSent] = React.useState(0);

  const isNotValidMail = !validateEmail(mail);

  const clickAction = () => {
    requireResetLink(mail)
      .then(data => {
        setSent(1);
      })
      .catch(e => {
        setSent(2);
        console.log(e);
      });
  };

  let buttonComponent;
  if (sent === 1)
    buttonComponent = <div style={{ width: '100%' }} className={classes.submit}><Alert severity="success">A mail was sent — check it out!</Alert></div>;
  else if (sent === 2)
    buttonComponent = <div style={{ width: '100%' }} className={classes.submit}><Alert severity="error">An error has occurred</Alert></div>;
  else {
    buttonComponent = <Button
      fullWidth
      variant="contained"
      color="primary"
      disabled={isNotValidMail}
      className={classes.submit}
      muiClasses={{ label: classes.label }}
      onClick={clickAction}
    >
      Send Mail
    </Button>
  }

  return (
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Link to='/' >
            <img src={logo} alt=''/>
          </Link>
          <Typography component="h1" variant="h5">
            Send a recovery mail
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            autoComplete="email"
            autoFocus
            value={mail}
            onChange={e => { setMail(e.target.value); }}
            classes={{ root: 'login-override' }}
          />
          {
            buttonComponent
          }
          <Link to='/signin' className={classes.link}>
            Back to login
          </Link>
        </div>
      </Container>
    </div>
  );
}
