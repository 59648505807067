import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { handleReservationsFetched } from './reservationReducer';
import { handleUserFetched } from './userReducer';
import statisticsReducer from './statisticsReducer';
import { handleMessages } from './messagesReducer';
import { handleBackofficeActions } from './backofficeReducer';
import { hotelReducer } from './hotelReducer';

const makeRootReducer = () => combineReducers({
  intl: intlReducer,
  toastr: toastrReducer,
  reservationData: handleReservationsFetched,
  statisticsData: statisticsReducer,
  identity: handleUserFetched,
  hotel: hotelReducer,
  messages: handleMessages,
  backofficeData: handleBackofficeActions
});

export default makeRootReducer;
