import {
  drawerWidth,
  transition,
  boxShadow,
  whiteColor
} from 'assets/jss/material-dashboard-react.js';

const sidebarStyle = theme => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 175px)',
    overflow: 'auto',
    width: '96px',
    zIndex: '4',
    overflowScrolling: 'touch',
    backgroundColor: whiteColor
  },
  sidebarMobileWrapper: {
    position: 'relative',
    height: 'calc(100vh - 195px)',
    overflow: 'auto',
    width: '96px',
    zIndex: '4',
    overflowScrolling: 'touch',
    backgroundColor: whiteColor
  }
});

export default sidebarStyle;
