import { connect } from 'react-redux';
import BadgeIcon from './index';
import { unreadMessages } from '../../selectors/newMessageSelector';

// read from redux store
const mapStateToProps = (state) => ({
  newMessages: unreadMessages(state)
});

// container component/smart component (interacts with redux)
const BadgeIconContainer = connect(
  mapStateToProps,
  null
)(BadgeIcon);

export default BadgeIconContainer;
