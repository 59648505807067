import React from 'react';
import PropTypes from 'prop-types';

import { chartOptions, chartOptionsPercentage } from 'variables/charts.js';

import StatsChart from './StatsChart';

export default function ConversationStats({ labels, allSeries }) {
  const firstChart = [
    { key: 'activationRate', label: 'Activation rate', data: allSeries.activationRate },
    { key: 'retentionRate', label: 'Retention rate', data: allSeries.retentionRate },
    { key: 'fallbackRate', label: 'Fallback rate', data: allSeries.fallbackRate },
    { key: 'selfServiceRate', label: 'Self Service rate', data: allSeries.selfServiceRate }
  ];

  const secondChart = [
    { key: 'interactionPerUser', label: 'Average interaction per user', data: allSeries.interactionPerUser }
  ];

  return (
    <div>
      <StatsChart labels={labels} config={chartOptionsPercentage} data={firstChart} />
      <div style={{ height: '15px' }} />
      <StatsChart labels={labels} config={chartOptions} data={secondChart} />
    </div>
  );
}

ConversationStats.propTypes = {
  labels: PropTypes.any,
  allSeries: PropTypes.shape({
    activationRate: PropTypes.any,
    retentionRate: PropTypes.any,
    fallbackRate: PropTypes.any,
    selfServiceRate: PropTypes.any,
    interactionPerUser: PropTypes.any
  })
}
