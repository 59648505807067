import { fetchMessages } from 'api';

// ------------------------------------
// Constants
// ------------------------------------
const ADD_MESSAGES = 'ADD_MESSAGES';
const UPDATE_FOR_CHAT = 'UPDATE_FOR_CHAT';
const READ_MESSAGE = 'READ_MESSAGE';

// ------------------------------------
// Actions
// ------------------------------------
export const loadMessages = (hotelId) => ({
  type: ADD_MESSAGES,
  payload: fetchMessages(hotelId)
});

export const updateForChat = (message) => ({
  type: UPDATE_FOR_CHAT,
  payload: JSON.parse(message)
});

export const messageRead = (chat) => ({
  type: READ_MESSAGE,
  payload: chat
})

// ------------------------------------
// Reducer
// ------------------------------------
/* botId(pin):""
   chatType(pin):"WHATSAPP"
   hotelId(pin):1
   messages: []
   userId(pin):"393478821417"
   userName(pin):"Andrea Francesco Iuorio"
   unreadMessage: false
*/

const initialState = {
  liveChat: []
};

const checkChat = (c1, c2) => c1.userId === c2.userId && c1.chatType === c2.chatType && c1.hotelId === c2.hotelId;

const handleUpdateForChat = (state, action) => {
  const payload = action.payload;
  const isNewChat = state.liveChat.find(c => checkChat(c, payload));
  if (!isNewChat) {
    const newChat = createChatFromUpdate(payload);
    return ({ ...state, liveChat: [...state.liveChat, newChat] });
  }
  const updatedLivechats = state.liveChat.map(chat => {
    if (checkChat(chat, payload)) {
      const newMessage = createMessageFromUpdate(payload);
      const messageIsAlreadyPresent = chat.messages.some(m => m.id === newMessage.id);
      return messageIsAlreadyPresent
        ? { ...chat, messages: chat.messages.map(m => m.id === newMessage.id ? newMessage : m) }
        : { ...chat, messages: chat.messages.concat([newMessage]), unreadMessage: true }
    }
    return chat
  });
  return ({ ...state, liveChat: updatedLivechats });
}

const handleReadMessage = (state, action) => {
  const payload = action.payload;
  const chatToUpdate = state.liveChat.find(c => checkChat(c, payload));
  if (!chatToUpdate || !chatToUpdate.unreadMessage)
    return state;

  const updatedLivechats = state.liveChat.map(c => checkChat(c, payload) ? { ...c, unreadMessage: false } : c);

  return ({ ...state, liveChat: updatedLivechats })
}

export function handleMessages(state = initialState, action) {
  if (action.type === ADD_MESSAGES) {
    const messages = action.payload.data || [];
    return ({ ...state, liveChat: messages.map(m => ({ ...m, unreadMessage: false })) });
  } else if (action.type === UPDATE_FOR_CHAT)
    return handleUpdateForChat(state, action)

  else if (action.type === READ_MESSAGE)
    return handleReadMessage(state, action)

  return state;
}

function createMessageFromUpdate({ time, message, ...rest }) {
  return { ...rest, date: time, text: message };
}

function createChatFromUpdate(payload) {
  return {
    botId: '',
    chatType: payload.chatType,
    hotelId: payload.hotelId,
    messages: [createMessageFromUpdate(payload)],
    unreadMessage: true,
    userId: payload.userId
  };
}
