import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, CircularProgress } from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';
import messagesStyle from './style';
import { sendWhatsappMessage } from 'api';
import { toastr } from 'react-redux-toastr';

const useLocalStyles = makeStyles(messagesStyle);

function SendMessageBox ({ currentHotelId, selectedChat, messageToSend, setMessageToSend }) {
  const classes = useLocalStyles();
  const [sendingMessage, setSendingMessage] = React.useState(false);

  const sendMessage = currentChat => {
    if (currentChat.chatType === 'WHATSAPP') {
      setSendingMessage(true);
      sendWhatsappMessage(currentHotelId, currentChat.userId, messageToSend)
        .then(() => {
          setMessageToSend('');
        })
        .catch(() => {
          toastr.error('Failed', 'Error sending message');
        })
        .finally(() => {
          setSendingMessage(false);
        });
    }
  };

  return <Grid container style={{ marginTop: '10xp', marginBottom: '10px', flexWrap: 'nowrap' }}>
    <TextareaAutosize
      id='message-box'
      rows={3}
      className={classes.chatMessageBox}
      value={messageToSend}
      onChange={ e => setMessageToSend(e.target.value) }
      style={{ border: 'none', resize: 'none' }}
    />
    <IconButton
      className={classes.sendButton}
      onClick={() => sendMessage(selectedChat)}
      disabled={!messageToSend || sendingMessage}
    >
      {sendingMessage
        ? <CircularProgress size={35}/>
        : <SendRounded fontSize={'large'}/>
      }
    </IconButton>
  </Grid>;
}

SendMessageBox.propTypes = {
  currentHotelId: PropTypes.number,
  messageToSend: PropTypes.string,
  setMessageToSend: PropTypes.func,
  selectedChat: PropTypes.shape({
    chatType: PropTypes.string,
    userId: PropTypes.string
  })
};

export default SendMessageBox;
