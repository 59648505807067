import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import ChatIcon from '@material-ui/icons/Chat';

const BadgeIcon = ({ newMessages, className }) => {
  return (
    <Badge
      color="primary"
      variant="dot"
      invisible={ !newMessages }
    >
      <ChatIcon className={className}/>
    </Badge>
  );
}

BadgeIcon.propTypes = {
  className: PropTypes.string,
  newMessages: PropTypes.bool
};

BadgeIcon.defaultProps = {
  newMessages: false
};

export default BadgeIcon;
