import React from 'react';

import { Card, Modal } from '@material-ui/core';

import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button';
import PropTypes from 'prop-types';

export default function TextModal({ open, onClose, onOk, textMessage }) {
  return (
    <Modal
      open={open}
      onClose={ onClose }
    >
      <Card
        style={ {
          width: '25%',
          position: 'absolute',
          float: 'left',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        } }
      >
        <CardBody>
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            {textMessage}
          </p>
        </CardBody>
        <CardFooter>
          <Button
            color='info'
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color='info'
            onClick={() => {
              onOk();
              onClose();
            }}
          >
            OK
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  )
}

TextModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  textMessage: PropTypes.string
};

TextModal.defaultProps = {
  open: false,
  onClose: () => {},
  onOk: () => {},
  textMessage: ''
};
