import React from 'react';
import PropTypes from 'prop-types';

import { chartOptions, chartOptionsEuro, chartOptionsPercentage } from 'variables/charts.js';
import StatsChart from './StatsChart';

export default function TransactionStats({ labels, allSeries }) {
  const bookingChart = [
    { key: 'bookingsGenerated', label: 'Bookings generated', data: allSeries.bookingsGenerated },
    { key: 'ancillariesGenerated', label: 'Ancillaries generated', data: allSeries.ancillariesGenerated }
  ];

  const revenueChart = [
    { key: 'revenueGenerated', label: 'Revenue generated', data: allSeries.revenueGenerated },
    { key: 'ancillariesRevenues', label: 'Ancillaries revenues', data: allSeries.ancillariesRevenues }
  ];

  const conversionRateChart = [
    { key: 'bookingConversionRate', label: 'Booking conversion rate', data: allSeries.bookingConversionRate },
    { key: 'ancillariesConversionRate', label: 'Ancillaries conversion rate', data: allSeries.ancillariesConversionRate }
  ];

  return (
    <div>
      <StatsChart labels={labels} config={chartOptions} data={bookingChart} />
      <div style={{ height: '15px' }} />
      <StatsChart labels={labels} config={chartOptionsEuro} data={revenueChart} />
      <div style={{ height: '15px' }} />
      <StatsChart labels={labels} config={chartOptionsPercentage} data={conversionRateChart} />
    </div>
  );
}

TransactionStats.propTypes = {
  labels: PropTypes.any,
  allSeries: PropTypes.shape({
    bookingsGenerated: PropTypes.any,
    revenueGenerated: PropTypes.any,
    ancillariesGenerated: PropTypes.any,
    ancillariesRevenues: PropTypes.any,
    bookingConversionRate: PropTypes.any,
    ancillariesConversionRate: PropTypes.any
  })
}
