import {
  whiteColor,
  grayColor,
  hexToRgb
} from 'assets/jss/material-dashboard-react.js';

const dashboardStyle = {
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  card: {
    borderRadius: '12px',
    boxShadow: '0 5px 25px 0 rgba(0, 0, 0, 0.07)'
  },
  label: {
    color: '#A9AEBC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '10px'
  },
  inputBox: {
    background: '#F1F2F7',
    borderRadius: '6px',
    height: '40px',
    paddingLeft: '13px',
    fontSize: '14px',
    display: 'flex'
  },
  icon: {
    color: '#858CA6',
    height: '40px'
  },
  input: {
    flexGrow: 1,
    paddingLeft: '10px',
    paddingRight: '10px',
    color: '#A9AEBC'
  },
  fieldContainer: {
    padding: '20px'
  },
  searchContainer: {
    borderRight: '3px solid #F1F2F7'
  },
  cardBody: {
    paddingTop: 0,
    paddingBottom: '0 !important',
    paddingLeft: 20
  },
  cardBodySection: {
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: 0,
    paddingRight: 0
  },
  labelWithPadding: {
    paddingTop: 20
  },
  inputSeparator: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  table: {
    borderCollapse: 'revert'
  },
  trBorderLeft: {
    borderRadius: '6px',
    backgroundColor: 'rgba(241,242,247,1)'
  },
  bold: {
    fontWeight: '500 !important'
  },
  trBorderRight: {
    borderRadius: '6px',
    backgroundColor: 'rgba(241,242,247,1)'
  },
  successText: {
    color: '#27AE60 !important'
  },
  warningText: {
    color: '#F2994A !important'
  },
  leftPaddingZero: {
    leftPadding: '0 !important'
  },
  dropdownHotel: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  marginRight10: {
    marginRight: '10px'
  },
  marginLeft10: {
    marginLeft: '10px'
  },
  noMargin: {
    margin: '0'
  },
  maxFullHeight: {
    maxHeight: '100%'
  }
};

export default dashboardStyle;
