import { ALL_STATISTICS } from '../actions';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  summary: {
    value: 0,
    change: 0
  },
  labels: [],
  values: {
    positiveFeedbackCountList: [],
    negativeFeedbackCountList: []
  }
};

export function feedbackStatisticsReducer(state = initialState, action) {
  if (!action || !action.payload || !action.payload[3])
    return state;

  if (action.type === ALL_STATISTICS) {
    const feedbackStatistics = action.payload[3].data;
    return {
      summary: feedbackStatistics.summary,
      labels: feedbackStatistics.labels,
      values: {
        positiveFeedbackCountList: feedbackStatistics.positiveFeedbackCountList,
        negativeFeedbackCountList: feedbackStatistics.negativeFeedbackCountList
      }
    }
  }

  return state;
}

export default feedbackStatisticsReducer;
