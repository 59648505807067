import React from 'react';
import PropTypes from 'prop-types';

import isFunction from 'lodash/isFunction';

// @material-ui/core components
import { withStyles, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import styles from 'assets/jss/material-dashboard-react/components/tableStyle.js';

const useStyles = makeStyles(styles);

const SortedTable = ({ tableHeaderData, tableData, orderDirection }) => {
  const [orderColumn, setOrderColumn] = React.useState(orderDirection);

  const classes = useStyles();

  const handleChangeSort = newColumnId => event => {
    const { columnId, dir } = orderColumn;
    const newOrder = newColumnId === columnId && dir === 'asc' ? 'desc' : 'asc';
    setOrderColumn({ columnId: newColumnId, dir: newOrder });
  };

  const sortTableData = (data, { columnId, dir }) => {
    const headerData = tableHeaderData.find(a => a.id === columnId);
    const comparator = headerData ? headerData.comparator : (a, b) => (0);

    return data.map(a => a).sort(
      (a, b) => {
        const val = comparator(a[columnId], b[columnId]);
        return dir === 'desc' ? val : -val;
      }
    );
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} >
        <TableHead>
          <TableRow>
            {tableHeaderData.map((header) => (
              <StyledTableHeader key={header.id}>
                {header.sortable
                  ? (
                    <TableSortLabel
                      active={orderColumn && orderColumn.columnId === header.id}
                      direction={orderColumn.dir}
                      onClick={handleChangeSort(header.id)}
                    >
                      {header.text}
                    </TableSortLabel>
                    )
                  : header.text
                }
              </StyledTableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortTableData(tableData, orderColumn).map(dto => (
            <StyledTableRow key={dto.id}>
              {
                tableHeaderData.map(header =>
                  <StyledTableCell
                    className={header.className && (isFunction(header.className) ? header.className(dto) : header.className) }
                    key={`${dto.id}-${header.id}`}
                  >
                    {header.render ? header.render(dto) : dto[header.id] }
                  </StyledTableCell>
                )
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SortedTable.defaultProps = {
  orderDirection: { columnId: 'id', dir: 'asc' }
};

SortedTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    sortable: PropTypes.bool,
    comparator: PropTypes.func,
    render: PropTypes.func
  })),
  orderDirection: PropTypes.shape({
    columnId: PropTypes.string,
    dir: PropTypes.string
  })
};

export default SortedTable;

const StyledTableHeader = withStyles(() => ({
  root: {
    fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
    color: '#858CA6',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderBottom: 0
  }
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  root: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    borderBottom: 0
  }
}))(TableCell);

const StyledTableRow = withStyles((_) => ({
  root: {
    '&:nth-of-type(odd)': {
      background: 'rgb(241,242,247)'
    },
    border: 0
  }
}))(TableRow);
