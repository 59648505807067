import React from 'react';
import PropTypes from 'prop-types';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    padding: 0,
    paddingTop: 10,
    margin: 0,
    background: '#F1F2F7',
    height: '40px',
    paddingLeft: '20px',
    borderRadius: '6px',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
  input: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)'
  }
};

const TTHDatePicker = ({ classes, ...props }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      placeholder="dd/mm/yyyy"
      margin="normal"
      className={classes.root}
      label=""
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      InputProps={{
        disableUnderline: true,
        className: classes.input
      }}
      {...props}
    />
  </MuiPickersUtilsProvider>
);

TTHDatePicker.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string
  })
}

export default withStyles(styles)(TTHDatePicker);
