import { fetchReservations } from 'api';

// ------------------------------------
// Constants
// ------------------------------------
const GET_RESERVATIONS_LIST = 'GET_RESERVATIONS_LIST';

// ------------------------------------
// Actions
// ------------------------------------
export const loadReservationsList =
  (dispatch, hotelId) =>
    fetchReservations(hotelId).then(res => dispatch({
      type: GET_RESERVATIONS_LIST,
      payload: res.data
    }))
      .catch(err => console.log(err));

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  reservations: []
};

export function handleReservationsFetched(state = initialState, action) {
  if (action.type === GET_RESERVATIONS_LIST)
    return { reservations: action.payload || [] };

  return state;
}
