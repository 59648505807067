import axios from 'axios';
import { format } from 'date-fns';

export const createHotel = hotel => axios.post('/api/hotels', hotel);
export const fetchHotelList = () => axios.get('/api/hotels');
export const getHotelById = id => axios.get(`/api/hotels/${id}`);
export const getHotelApiKey = id => axios.get(`/api/hotels/${id}/apikey`)
export const resetHotelApiKey = id => axios.post(`/api/hotels/${id}/apikey/reset`)
export const getHotelByInstallationName = installationName => axios.get(`/api/hotels/query?installation=${installationName}`);
export const modifyHotel = hotel => axios.put(`/api/hotels/${hotel.id}`, hotel);

export const getUserHotelConfig = id => axios.get(`/api/user/hotels/${id}`)
export const modifyUserHotelConfig = hotel => axios.put(`/api/user/hotels/${hotel.id}`, hotel)

export const fetchReservations = hotelId => axios.get(`/api/reservations/${hotelId}`);

const formatDate = raw => format(raw, 'yyyy-MM-dd');

const fetchStatistics = url => (from, to, hotels, timeAggregation) => {
  try {
    const fromDate = formatDate(from);
    const toDate = formatDate(to);

    return axios.get(url, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        hotels: hotels,
        timeAggregation: timeAggregation
      }
    });
  } catch (e) {
    return new Promise((resolve, reject) => {
      reject(e);
    })
  }
}

export const fetchConversationStatistics = fetchStatistics('/api/statistics/conversations');

export const fetchLeadStatistics = fetchStatistics('/api/statistics/leads');

export const fetchTransactionStatistics = fetchStatistics('/api/statistics/transactions');

export const fetchFeedbackStatistics = fetchStatistics('/api/statistics/feedbacks');

export const fetchMessages = hotelId => axios.get(`/api/messages/${hotelId}`);

export const createUser = user => axios.post('/api/user', user);
export const modifyUser = user => axios.put('/api/user/' + user.id, user);
export const fetchUserList = () => axios.get('/api/user/list');
export const fetchMe = () => axios.get('/api/user/me');
export const fetchUserWithId = id => axios.get('/api/user/' + id);
export const fetchUserByMail = mail => axios.get(`/api/user/query?mail=${mail}`);
export const changeUserPassword = (userId, password) => axios.put('/api/user/' + userId + '/password', password);

export const fetchBookingEngines = () => axios.get('/api/booking-engine');

export const requireResetLink = mail => axios.post('/api/public/request-password', { mail });
export const resetPassword = payload => axios.post('/api/public/reset-password', payload);

export const isTokenValid = token => axios.get(`/api/public/check-token/${token}`);

export const sendWhatsappMessage = (hotelId, userId, message) => axios.post('/api/messages/whatsapp',
  { hotelId: hotelId, userId: userId, message: message }
);

export const fetchExtServicesList = () => axios.get('/api/externalservices');
export const fetchExternalServiceByName = (serviceName) => axios.get(`/api/externalservices/query?name=${serviceName}`);
export const fetchExternalServiceById = id => axios.get('/api/externalservices/' + id);
export const createExternalService = (service) => axios.post('/api/externalservices', service);
export const modifyExternalService = (service) => axios.put('/api/externalservices/' + service.id, service);
