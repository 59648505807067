import { ADD_USER } from 'store/reducer/actions';

// ------------------------------------
// Constants
// ------------------------------------
const SELECT_HOTEL = 'SELECT_HOTEL';

// ------------------------------------
// Actions
// ------------------------------------
export const changeHotel = hotelId => ({
  type: SELECT_HOTEL,
  payload: hotelId
});

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = 0;

export function hotelReducer(state = initialState, action) {
  if (action.type === SELECT_HOTEL)
    return action.payload;
  if (action.type === ADD_USER) {
    const userData = action.payload.data;
    return userData && userData.hotels && userData.hotels.length > 0
      ? userData.hotels[0].id
      : state
  }

  return state;
}
