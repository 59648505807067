import React from 'react';
import PropTypes from 'prop-types';

import { barChartOptions } from 'variables/charts.js';
import StatsChart from './StatsChart';

export default function FeedbackStats({ labels, allSeries }) {
  const chartData = [
    { key: 'positiveFeedbackCountList', label: 'Positive feedbacks', data: allSeries.positiveFeedbackCountList },
    { key: 'negativeFeedbackCountList', label: 'Negative feedbacks', data: allSeries.negativeFeedbackCountList }
  ];

  return (
    <div>
      <StatsChart labels={labels} config={barChartOptions} data={chartData} type="Bar"/>
    </div>
  );
}

FeedbackStats.propTypes = {
  labels: PropTypes.any,
  allSeries: PropTypes.shape({
    positiveFeedbackCountList: PropTypes.any,
    negativeFeedbackCountList: PropTypes.any
  })
}
