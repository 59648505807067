import React from 'react';
import PropTypes from 'prop-types';
// api
import { createExternalService } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import ExternalServiceForm from 'views/BackOffice/ExternalServices/components/ExternalServiceForm';

export default function CreateExternalService({ history }) {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ExternalServiceForm
            history={history}
            headerMsg={'Create External Service'}
            btnAction={createExternalService}
            btnProps={{
              btnText: 'Create External Service',
              successMsg: 'External Service Created',
              failureMsg: 'Unable to create a new external service'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

CreateExternalService.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}
