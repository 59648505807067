/* eslint react/prop-types: 0 */
// TODO: Fix proptypes

import React from 'react';
import classNames from 'classnames';

import { Grid, Card, CardContent } from '@material-ui/core';
import ForumIcon from '@material-ui/icons/ForumOutlined';
import GpsIcon from '@material-ui/icons/GpsFixedOutlined';
import CardIcon from '@material-ui/icons/CreditCardOutlined';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import TTHDatePicker from 'components/TTHDatePicker/TTHDatePicker';
import ConversationStats from './containers/ConversationStatsContainer';
import LeadGenerationStats from './containers/LeadGenerationStatsContainer';
import TransactionStats from './containers/TransactionStatsContainer';
import FeedbackStats from './containers/FeedbackStatsContainer';
import MultipleSelect from 'components/MultipleSelect';
import TimeGroup from 'components/CustomButtonGroup';

import { useStyles } from './styles';

function Dashboard({ statisticsFilter, conversation, leadGeneration, transaction, feedback, forceRefresh, userHotelList, activeHotelId }) {
  const classes = useStyles();
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(statisticsFilter.dateFrom);
  const [selectedDateTo, setSelectedDateTo] = React.useState(statisticsFilter.dateTo);
  const [selectedHotel, setSelectedHotel] = React.useState(undefined);
  const [timeAggregation, setTimeAggregation] = React.useState(0);

  React.useEffect(() => {
    if (activeHotelId)
      setSelectedHotel([activeHotelId]);
  }, [activeHotelId]);

  React.useEffect(() => {
    if (selectedHotel)
      forceRefresh(selectedDateFrom, selectedDateTo, selectedHotel, timeAggregation);
  },
  [forceRefresh, selectedDateFrom, selectedDateTo, selectedHotel, timeAggregation]);

  const handleDateFromChange = (date) => {
    if (date.getTime() !== selectedDateFrom.getTime()) setSelectedDateFrom(date);
    if (date.getTime() > selectedDateTo.getTime()) setSelectedDateFrom(selectedDateTo)
  };
  const handleDateToChange = (date) => {
    if (date.getTime() !== selectedDateTo.getTime()) setSelectedDateTo(date);
    if (date.getTime() < selectedDateFrom.getTime()) setSelectedDateTo(selectedDateFrom)
  };

  return (
    <div>
      <GridContainer className={classes.cardBody} style={{ position: 'sticky', top: '15px', zIndex: '2' }}>
        <GridItem xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardBody}>
              <Grid container>
                <Grid item xs={6} sm={6} md={4} className={classNames(classes.fieldContainer, classes.cardBodySection)}>
                  <div className={classes.label}>Choose a period of time:</div>
                  <Grid container>
                    <Grid item xs={5}>
                      <TTHDatePicker
                        value={selectedDateFrom}
                        onChange={handleDateFromChange}
                        maxDate={selectedDateTo}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.inputSeparator}>
                      <div className={classes.label} style={{ margin: 0 }}><span>to</span></div>
                    </Grid>
                    <Grid item xs={5} >
                      <TTHDatePicker
                        value={selectedDateTo}
                        onChange={handleDateToChange}
                        minDate={selectedDateFrom}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={4} className={classNames(classes.fieldContainer, classes.searchSecondColumn)}>
                  <div className={classes.label}>Select hotel:</div>
                  <MultipleSelect
                    dataList={userHotelList}
                    selectedElements={selectedHotel || []}
                    setSelectedElements={setSelectedHotel}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} className={classNames(classes.fieldContainer, classes.searchSecondColumn)}>
                  <div className={classes.label}>Aggregation Time:</div>
                  <TimeGroup
                    items={['Day', 'Week', 'Month']}
                    selected={timeAggregation}
                    onChange={setTimeAggregation}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </GridItem>
      </GridContainer>
      <Grid container className={classes.statsContainer} spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardHeader className={classes.statisticsCard}>Conversations</CardHeader>
            <CardContent className={classes.headerContent}>
              <div>
                <ForumIcon className={classes.statsIcon}/> <span className={classes.statsValue}>{conversation.summary.value}</span>
              </div>
              <div className={classes.statsComparison}>
                <span className={classes.percentage}>{formatChange(conversation.summary.change)}%</span> <span className={classes.comparisonPeriod}>since last month</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3} >
          <Card className={classes.card}>
            <CardHeader className={classes.statisticsCard}>Leads</CardHeader>
            <CardContent className={classes.headerContent}>
              <div>
                <GpsIcon className={classes.statsIcon}/> <span className={classes.statsValue}>{leadGeneration.summary.value}</span>
              </div>
              <div className={classes.statsComparison}>
                <span className={classes.percentage}>{formatChange(leadGeneration.summary.change)}%</span> <span className={classes.comparisonPeriod}>since last month</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardHeader className={classes.statisticsCard}>Transactions</CardHeader>
            <CardContent className={classes.headerContent}>
              <div>
                <CardIcon className={classes.statsIcon}/> <span className={classes.statsValue}>{transaction.summary.value}</span>
              </div>
              <div className={classes.statsComparison}>
                <span className={classes.percentage}>{formatChange(transaction.summary.change)}%</span> <span className={classes.comparisonPeriod}>since last month</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardHeader className={classes.statisticsCard}>Feedbacks</CardHeader>
            <CardContent className={classes.headerContent}>
              <div>
                <CardIcon className={classes.statsIcon}/> <span className={classes.statsValue}>{feedback.summary.value}</span>
              </div>
              <div className={classes.statsComparison}>
                <span className={classes.percentage}>{formatChange(feedback.summary.change)}%</span> <span className={classes.comparisonPeriod}>since last month</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.statsContainer} spacing={4}>
        <Grid item xs={12}>
          <Card className={classNames(classes.card, classes.cardEqualHeight)}>
            <CardHeader className={classes.statisticsCard}>Conversation stats</CardHeader>
            <CardContent className={classNames(classes.headerContent, classes.chartContainer)}>
              <ConversationStats />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classNames(classes.card, classes.cardEqualHeight)}>
            <CardHeader className={classes.statisticsCard}>Lead Generation stats</CardHeader>
            <CardContent className={classNames(classes.headerContent, classes.chartContainer)}>
              <LeadGenerationStats />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classNames(classes.card, classes.cardEqualHeight)}>
            <CardHeader className={classes.statisticsCard}>Transaction stats</CardHeader>
            <CardContent className={classNames(classes.headerContent, classes.chartContainer)}>
              <TransactionStats />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classNames(classes.card, classes.cardEqualHeight)}>
            <CardHeader className={classes.statisticsCard}>Feedback stats</CardHeader>
            <CardContent className={classNames(classes.headerContent, classes.chartContainer)}>
              <FeedbackStats />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const formatChange = (change) => {
  if (change > 0)
    return '+ ' + change;
  else if (change < 0)
    return '- ' + Math.abs(change);
  else
    return change;
};

export default Dashboard;
