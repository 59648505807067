import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from 'components/CustomButtons/Button';
import logo from 'logo.svg';

import 'assets/css/signin.css';
import { resetPassword, isTokenValid } from 'api';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    backgroundColor: '#FFF'
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  label: {
    fontSize: '16px'
  },
  link: {
    textDecoration: 'none',
    color: '#3f51b5',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em !important'
  },
  failureContainer: {
    paddingTop: '15px',
    paddingBottom: '10px'
  },
  failureMessage: {
    fontSize: '17px'
  },
  loadingSpinner: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#5FBFBB'
  }
}));

function ResetPasswordForm({ token }) {
  const classes = useStyles();

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [sent, setSent] = React.useState(0);

  const isNotValidPassword = !password || !confirmPassword || password !== confirmPassword;

  const clickAction = () => {
    resetPassword({
      uuid: token,
      pass: password
    })
      .then(data => {
        setSent(1);
      })
      .catch(e => {
        setSent(2);
        console.log(e);
      });
  };

  let buttonComponent;
  if (sent === 1)
    buttonComponent = <div style={{ width: '100%' }} className={classes.submit}><Alert severity="success">Your password has been updated</Alert></div>;
  else if (sent === 2)
    buttonComponent = <div style={{ width: '100%' }} className={classes.submit}><Alert severity="error">An error has occurred</Alert></div>;
  else {
    buttonComponent = <Button
      fullWidth
      variant="contained"
      color="primary"
      disabled={isNotValidPassword}
      className={classes.submit}
      muiClasses={{ label: classes.label }}
      onClick={clickAction}
    >
      Update Password
    </Button>
  }

  return (<div>
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label="Password"
      type="password"
      value={password}
      onChange={e => { setPassword(e.target.value); }}
      classes={{ root: 'login-override' }}
    />
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label="Confirm Password"
      type="password"
      value={confirmPassword}
      onChange={e => { setConfirmPassword(e.target.value); }}
      classes={{ root: 'login-override' }}
    />
    {
      buttonComponent
    }
  </div>);
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string
}

export default function ResetPassword() {
  const classes = useStyles();

  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');

  const [isValid, setValid] = React.useState(0);

  React.useEffect(() => {
    isTokenValid(token).then((response) => {
      setValid(1);
    }).catch((err) => {
      console.log('Error while fetching token validity: ', err.stack);
      setValid(2);
    })
  }, []);

  return (
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Link to='/' >
            <img src={logo} alt=''/>
          </Link>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {
            (isValid === 0) &&
            <CircularProgress className={classes.loadingSpinner} />
          }
          {
            (isValid === 1) &&
              <ResetPasswordForm token={token}/>
          }
          {
            (isValid === 2) &&
            <div className={classes.failureContainer}>
              <Alert className={classes.failureMessage} severity="error">Sorry, your reset link is expired</Alert>
            </div>
          }
          <Link to='/signin' className={classes.link}>
            Go to login
          </Link>
        </div>
      </Container>
    </div>
  );
}
