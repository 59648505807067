import React from 'react';
import PropTypes from 'prop-types';

// api
import { getHotelApiKey } from 'api';

import { Modal } from '@material-ui/core';

// components
import Card from 'components/Card/Card';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button';
import ModalResetApiKey from './ModalResetApiKey';

const ModalApiKey = ({ open, onClose, hotelId }) => {
  const [apikey, setApiKey] = React.useState('');
  const [showResetApiKey, setShowResetApiKey] = React.useState(false);

  React.useEffect(() => {
    if (hotelId) {
      getHotelApiKey(hotelId).then(res => {
        setApiKey(res.data);
      });
    }
  }, [hotelId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={ onClose }
      >
        <>
          <Card
            style={ {
              width: '25%',
              position: 'absolute',
              float: 'left',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            } }
          >
            <CardBody>
              <CustomInput
                labelText='Api Key'
                id='apikey'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: apikey
                }}
              />
            </CardBody>
            <CardFooter>
              <Button
                color='info'
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                color='info'
                onClick={() => {
                  setShowResetApiKey(true);
                }}
              >
                Reset Api Key
              </Button>
            </CardFooter>
          </Card>
        </>
      </Modal>
      <ModalResetApiKey
        open={showResetApiKey}
        onClose={() => { setShowResetApiKey(false); }}
        onReset={(apiKey) => { setApiKey(apiKey); onClose(); }}
        hotelId={hotelId}
      />
    </div>
  );
};

export default ModalApiKey;

ModalApiKey.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hotelId: PropTypes.number
};
