import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// common components
import CardHeader from 'components/Card/CardHeader';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,0.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
};

const useStyles = makeStyles(styles);

const FormHeader = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <CardHeader color='primary'>
      <h4 className={classes.cardTitleWhite}>{title}</h4>
      {subtitle
        ? <p className={classes.cardCategoryWhite}>{subtitle}</p>
        : null
      }
    </CardHeader>
  );
};

export default FormHeader;

FormHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
