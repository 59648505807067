import React from 'react';
import { withStyles, Checkbox } from '@material-ui/core';

export const SeriesACheckbox = withStyles({
  root: {
    color: '#5fbfbb',
    '&$checked': {
      color: '#5fbfbb'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export const SeriesBCheckbox = withStyles({
  root: {
    color: '#1d51c2',
    '&$checked': {
      color: '#1d51c2'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export const SeriesCCheckbox = withStyles({
  root: {
    color: '#ffaa00',
    '&$checked': {
      color: '#ffaa00'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export const SeriesDCheckbox = withStyles({
  root: {
    color: '#e74c3c',
    '&$checked': {
      color: '#e74c3c'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
