import {
  defaultFont,
  whiteColor
} from 'assets/jss/material-dashboard-react.js';

const brandStyle = {
  logo: {
    backgroundColor: whiteColor,
    boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.05)'
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor
    },
    display: 'flex',
    justifyContent: 'center'
  }
};

export default brandStyle;
