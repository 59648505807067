import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// api
import { fetchUserWithId, modifyUser } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import UserForm from 'views/BackOffice/Users/components/UserForm';

export default function ModifyUser({ history }) {
  const { id } = useParams();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
              <UserForm
                retrieveUser={() => fetchUserWithId(id).then(res => res.data)}
                editablePassword={false}
                disableMail={true}
                history={history}
                headerMsg={'Modify Profile'}
                btnAction={modifyUser}
                btnProps={{
                  btnText: 'Modify User',
                  successMsg: 'User modified',
                  failureMsg: 'Unable to modify user'
                }}
              />
        </GridItem>
      </GridContainer>
    </div>
  );
}

ModifyUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}
