import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

export const hotelStyle = makeStyles({
  '@global': {
    'tr td:last-child': {
      padding: '0px 10px'
    }
  },
  ...styles
});
