import { connect } from 'react-redux';

import LeadGenerationStats from '../components/LeadGenerationStats';

const mapStateToProps = (state) => ({
  labels: state.statisticsData.leadGeneration.labels,
  allSeries: state.statisticsData.leadGeneration.values
});

// container component/smart component (interacts with redux)
const LeadGenerationStatsContainer = connect(
  mapStateToProps,
  null
)(LeadGenerationStats);

export default LeadGenerationStatsContainer;
